@charset "UTF-8";

* {
  margin: 0;
  padding: 0;
  border: 0;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, taxtarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*----------------------------------*/
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
.wrapper::before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background: url("~/static/design/page-bg/homepage-bg-light-desktop.svg");
  background-position: center;
  background-size: 100%;
  z-index: -1;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
@media (max-width: 1200px) {
  .wrapper::before {
    background: url("~/static/design/page-bg/homepage-bg-light-mobile.svg");
    background-size: 100%;
  }
}
.wrapper::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
  background: url("~/static/design/page-bg/homepage-bg-dark-desktop.svg");
  background-position: center;
  background-size: 100%;
  z-index: -1;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
@media (max-width: 1200px) {
  .wrapper::after {
    background: url("~/static/design/page-bg/homepage-bg-dark-mobile.svg");
    background-size: 100%;
  }
}

[class*=__container] {
  width: 100%;
  max-width: 1300px;
  padding: 0 10px;
  margin: 0 auto;
}

.main {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-top: 135px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 480px) {
  .main {
    margin-top: calc(115px + 20 * (100vw - 320px) / 160);
  }
}

body {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
}

.stroke-button {
  background-color: transparent;
  border: 1px solid #2A59FF;
  -webkit-transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
  color: #1E222A;
}
.stroke-button:hover {
  background-color: #2A59FF;
  color: #fff;
}

.filled-button {
  background-color: #2A59FF;
  border: 1px solid #2A59FF;
  color: #fff;
  -webkit-transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
}
.filled-button:hover {
  background-color: transparent;
  color: #1E222A;
}

.btn {
  padding: .75rem 2rem;
  border-radius: .5rem;
}

.swal2_confirm,
.swal2-confirm {
  background: #2A59FF;
  color: #fff;
  margin: 0 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  order: 2;
  transition: background .3s ease-out;
  will-change: background;
}
.swal2_confirm:hover,
.swal2-confirm:hover {
  color: #fff;
  background: #0052db
}
.swal2_cancel.danger,
.swal2-cancel {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: #d00;
  color: #fff;
  transition: background .3s ease-out;
  will-change: background;
}
.swal2_cancel.danger:hover,
.swal2-cancel:hover {
  color: #fff;
  background: #ee0000
}

button, a, .header-main-actions__el, .header-navigation__el {
  -webkit-tap-highlight-color: transparent;
}

.filter-button {
  -webkit-transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s;
}
.filter-button:hover,
.filter-button:active {
  background-color: #1c3aa6
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

body {
  background-color: #E8EBEF;
  -webkit-transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
}
body .wrapper::before {
  opacity: 1;
}
body .wrapper::after {
  opacity: 0;
}

body .main__watchlist .watchlist__header {
  border-top: 1px solid #B5BACB;
}
body .main__watchlist .watchlist-title__title, body .main__watchlist .watchlist-showing__title {
  color: #1E222A;
}
body .main__watchlist .watchlist-categories {
  border-left: 1px solid #B5BACB;
  border-right: 1px solid #B5BACB;
}
body .main__watchlist .watchlist-categories__el {
  color: #1E222A;
}
body .main__watchlist .watchlist-categories__el:not(:last-child)::after {
  background-color: #B5BACB;
}
body .main__watchlist .watchlist-categories__el_curent {
  color: #2A59FF;
}
body .main__watchlist .watchlist-showing::before {
  opacity: 1;
}
body .main__watchlist .watchlist-showing::after {
  opacity: 0;
}
body .main__watchlist .watchlist-showing .select__body {
  background-color: rgba(243, 247, 251, 0.9);
}
body .main__watchlist .watchlist-showing .select__item:hover {
  background-color: #e9e9e9;
}
body .pagination .show-rows .select__body {
  background-color: rgba(243, 247, 251, 0.9);
}
body .pagination .show-rows .select__item:hover {
  background-color: #e9e9e9;
}
body .main__watchlist .watchlist-filters__name {
  color: #1E222A;
}
body .main__watchlist .watchlist-filters__button_filter {
  border: 1px solid #B5BACB;
}
body .main__watchlist .watchlist-filters__button_filter::before {
  opacity: 0;
}
body .main__watchlist .watchlist-orientation__button {
  border: 1px solid #B5BACB;
}
body .main__watchlist .watchlist-orientation__button::before {
  opacity: 0;
}
body .main__watchlist .watchlist-orientation__button:not(.disabled):hover {
  border: 1px solid #2A59FF;
}
body .main__watchlist .select__body-wrapper::before {
  opacity: 1;
}
body .main__watchlist .select__body-wrapper::after {
  opacity: 0;
}
body .main__watchlist .watchlist-categories-spoiler {
  border-left: 1px solid #B5BACB;
  border-right: 1px solid #B5BACB;
}
@media (max-width: 767.98px) {
  body .main__watchlist .watchlist-categories-spoiler {
    border-right: 0px;
  }
}
body .filter .select__item:hover,
body .main__watchlist .watchlist-categories-spoiler .select__item:hover {
  background-color: #e9e9e9;
}
body .main__watchlist .watchlist-main__header {
  background-color: #f7faff;
  border: 1px solid transparent;
}
body .main__watchlist .watchlist-legend:not(:nth-child(-n+2)), body .main__watchlist .watchlist-product__part:not(:nth-child(-n+2)) {
  border-left: 1px solid #DFE3F0;
}
body .main__watchlist .watchlist-main::before {
  opacity: 1;
}
body .main__watchlist .watchlist-main::after {
  opacity: 0;
}
body .main__watchlist .watchlist-product {
  border: 1px solid transparent;
  background-color: #f2f4fd;
}
body .main__watchlist .watchlist-product:nth-child(2n) {
  background-color: #f7faff;
}
body .main__watchlist .watchlist-product:nth-child(2n) .watchlist-product-name {
  background-color: #f7faff;
}
body .main__watchlist .watchlist-product:hover .watchlist-product-name__full-name {
  color: #2A59FF;
}
body .main__watchlist .watchlist-product-name {
  background-color: rgb(242, 244, 253);
}
body .main__watchlist .watchlist-product__num {
  border: 1px solid #DFE3F0;
}
body .main__watchlist .watchlist-product-name__full-name {
  color: #1E222A;
}
body .main__watchlist .watchlist-product-settings__button {
  border: 1px solid #B5BACB;
}
body .main__watchlist .watchlist-product-settings__button span {
  background-color: #2A59FF;
}
body .main__watchlist .watchlist-product-settings__button span {
  background-color: #2A2E3A;
}
body .main__watchlist .watchlist-product-settings__button._opened span {
  background-color: #2A59FF;
}
body .main__watchlist .watchlist-main._in-the-end::before, body .main__watchlist .watchlist-main._in-the-end::after {
  opacity: 0;
}
body .filter {
  background: rgba(243, 247, 251, 0.9);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
body .filter .select__body {
  background: #F2F6FA;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
body .filter .filter__button_stroke {
  color: #1E222A;
}
body .filter .filter-search__input, body .filter .filter-range__input {
  color: #1E222A;
}
body .main-pagination__nav-btn {
  border: 1px solid #B5BACB;
}
body .main-pagination__nav-btn::before {
  opacity: 0;
}

body .watchlist_mod .watchlist-title::before {
  opacity: 1;
}
body .watchlist_mod .watchlist-title::after {
  opacity: 0;
}
body .watchlist_mod .watchlist-showing {
  border-right: 0;
}
body .watchlist_mod .watchlist-showing:before, body .watchlist_mod .watchlist-showing::after {
  display: none;
}
body .watchlist_mod .watchlist-showing .select__body {
  border: 0;
}
body .watchlist_mod .watchlist-filters__button_filter._opened {
  border: 1px solid transparent;
}
body .watchlist_mod .watchlist-orientation__button._current {
  border: 1px solid transparent;
}
body .watchlist_mod .filter, body .watchlist_mod .filter__wrapper {
  background: transparent;
}
body .watchlist_mod .select__header, body .watchlist_mod .select__body, body .watchlist_mod .filter-search {
  border: 1px solid #B5BACB;
}
body .watchlist .watchlist-product-status__status {
  background-color: #DFE4F0;
}

body .watchlist-main__list._grid .watchlist-product-nft {
  border-left: 0px;
}
body .watchlist-main__list._grid .watchlist-product-sale {
  border-left: 0;
}
body .watchlist-main__list._grid .watchlist-product-links {
  border-left: 0;
}
body .watchlist-main__list._grid .watchlist-product {
  background-color: #f7faff;
  border-radius: 8px;
}
body .watchlist-main__list._grid .watchlist-product .watchlist-product-name {
  background-color: #f7faff;
}

body .watchlist-product-more__button {
  border: 1px solid #B5BACB;
}

body .watchlist-showing__select .select__header {
  border: 1px solid transparent;
}
body .trends-navigation .main-trends__title {
  color: #1E222A;
}
body .trends-navigation .trends-navigation__select.is-active .trends-navigation__spoiler {
  border: 1px solid transparent;
}
body .trends-navigation .select__body {
  background-color: rgba(243, 247, 251, 0.8980392157);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
body .blockchain .blockchain-main {
  background-color: #f7faff;
  border: 1px solid transparent;
}
body .blockchain .blockchain-main__preview::before {
  opacity: 1;
}
body .blockchain .blockchain-main__preview::after {
  opacity: 0;
}
body .blockchain .info-blockchain__subtitle, body .blockchain .info-blockchain__paragraph, body .blockchain .blockchain-statistic__headline, body .blockchain .blockchain-statistic__label, body .blockchain .blockchain-grow__text, body .blockchain .blockchain-statistic__value, body .blockchain .card-info-blockchain__list, body .blockchain .card-info-blockchain__title, body .blockchain .card-info-blockchain__value, body .blockchain .attribute-card {
  color: #4A596D;
}
body .blockchain .blockchain-grow.plus .blockchain-grow__text {
  color: #71BA57;
}
body .blockchain .blockchain-grow.minus .blockchain-grow__text {
  color: #B14C6B;
}
body .blockchain .blockchain-statistic__part:not(:last-child) {
  border-right: 1px solid rgba(181, 186, 203, 0.5);
}
body .blockchain .blockchain-statistic {
  border-right: 1px solid rgba(181, 186, 203, 0.5);
  border-left: 1px solid rgba(181, 186, 203, 0.5);
}
@media (max-width: 991.98px) {
  body .blockchain .blockchain-statistic {
    border: 0;
    border-top: 1px solid rgba(181, 186, 203, 0.5);
  }
}
body .blockchain .links-blockchain__button {
  background-color: #f7faff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
}
body .blockchain .links-blockchain__text {
  color: #55606F;
}
@media (max-width: 667.98px) {
  body .blockchain .links-blockchain {
    border-top: 1px solid rgba(181, 186, 203, 0.5);
  }
}
body .blockchain .blockchain-body__card {
  background-color: #f7faff;
  border: 1px solid transparent;
}
body .section-button__button {
  background-color: #fff;
  color: #4A596D;
}
body .section-button__button:hover {
  background-color: #ddd;
  color: #515764;
}
body .also-watch-card {
  background-color: #f7faff;
  border: 1px solid rgba(197, 199, 207, 0.7);
}
body .also-watch-box::before {
  opacity: 1;
}
body .also-watch-box::after {
  opacity: 0;
}
body .also-watch-box._in-the-end::before, body .also-watch-box._in-the-end::after {
  opacity: 0;
}
body .also-watch::before {
  opacity: 1;
}
body .also-watch::after {
  opacity: 0;
}
body .currency-info .currency-graph__content {
  background-color: #f7faff;
  border: 1px solid transparent;
}
body .currency-info .currency-graph__dark-img {
  display: none;
}
body .currency-info .currency-graph__light-img {
  display: block;
}
body .currency-info .currency-characteristic__el {
  border: 1px solid transparent;
  background-color: #f2f4fd;
  color: #4A596D;
}
body .currency-info .currency-characteristic__el:nth-child(2n) {
  background-color: #f7faff;
}
body .currency-info .currency-characteristic__name {
  color: #4A596D;
}
body .currency-info .live-chat__chat {
  background-color: #f7faff;
  border: 1px solid transparent;
}
body .currency-info .post-comment__button {
  color: #4A596D;
}
body .currency-info .post-comment__button:hover {
  color: #2A59FF;
}
body .currency-info .user-live-chat__about {
  color: #4A596D;
}
body .currency-info .post-comment__area {
  background-color: #f5f8ff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}
body .currency-info .post-comment__input {
  color: #4A596D;
}
body .currency-info .comments-list .post-comment__comment {
  color: #4A596D;
}
body .currency-info .comments-list .post-comment__button_red:hover {
  color: #B14C6B;
}
body .currency-info .comments-list .post-comment__button_green:hover {
  color: #71BA57;
}
body .currency-info .comments-list::-webkit-scrollbar {
  background-color: #fff;
}
body .crypto-currency__box {
  background-color: #f7faff;
  border: 1px solid transparent;
}
body .crypto-currency .crypto-currency__paragraph, body .crypto-currency .links-list__text, body .crypto-currency .middle-content__title, body .crypto-currency .middle-content__price, body .crypto-currency .stat-middle-content__value, body .crypto-currency .stat-middle-content__label, body .crypto-currency .crypto-currency-converter__label, body .crypto-currency .crypto-currency-converter__input, body .crypto-currency .crypto-currency-about__subtitle, body .crypto-currency .about-range__labels {
  color: #4A596D;
}
body .crypto-currency .crypto-currency__paragraph a, body .crypto-currency .links-list__text a, body .crypto-currency .middle-content__title a, body .crypto-currency .middle-content__price a, body .crypto-currency .stat-middle-content__value a, body .crypto-currency .stat-middle-content__label a, body .crypto-currency .crypto-currency-converter__label a, body .crypto-currency .crypto-currency-converter__input a, body .crypto-currency .crypto-currency-about__subtitle a, body .crypto-currency .about-range__labels a {
  color: #4A596D;
}
body .crypto-currency .links-list__card {
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}
body .crypto-currency .middle-content__part:not(:nth-child(5)) {
  border-right: 1px solid rgba(181, 186, 203, 0.3);
}
@media (max-width: 991.98px) {
  body .crypto-currency .middle-content__part:nth-child(4) {
    border: 0;
  }
  body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 1px solid rgba(181, 186, 203, 0.3);
  }
  body .crypto-currency .middle-content__part:nth-child(6) {
    border-right: 0;
  }
}
@media (max-width: 767.98px) {
  body .crypto-currency .middle-content__part:nth-child(3), body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 0;
  }
  body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 1px solid rgba(181, 186, 203, 0.3);
  }
  body .crypto-currency .middle-content__part:nth-child(2) {
    border: 0;
  }
}
@media (max-width: 480px) {
  body .crypto-currency .middle-content__part:first-child {
    border-right: 0;
    border-bottom: 1px solid rgba(181, 186, 203, 0.3);
  }
  body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 0;
    border-top: 1px solid rgba(181, 186, 203, 0.3);
  }
  body .crypto-currency .middle-content__part:nth-child(3) {
    border-right: 1px solid rgba(181, 186, 203, 0.3);
  }
}
body .crypto-currency .middle-content {
  border-top: 1px solid rgba(181, 186, 203, 0.3);
  border-bottom: 1px solid rgba(181, 186, 203, 0.3);
}
body .crypto-currency .middle-content__wrapper::before {
  opacity: 1;
}
body .crypto-currency .middle-content__wrapper::after {
  opacity: 0;
}
body .crypto-currency .select__body {
  background-color: rgba(243, 247, 251, 0.9);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
body .crypto-currency .select__item:hover {
  background-color: #e9e9e9;
}
body .crypto-currency .crypto-currency-converter__body {
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}
@media (max-width: 767.98px) {
  body .crypto-currency .supply {
    border-top: 1px solid rgba(181, 186, 203, 0.3);
    border-bottom: 1px solid rgba(181, 186, 203, 0.3);
  }
}
@media (min-width: 480px) {
  body .crypto-currency .fully-cap::before, body .crypto-currency .crypto-currency-tags::before {
    opacity: 1;
  }
  body .crypto-currency .fully-cap::after, body .crypto-currency .crypto-currency-tags::after {
    opacity: 0;
  }
}
body .crypto-currency .crypto-currency-tags__button {
  background-color: transparent;
  border: 1px solid rgba(181, 186, 203, 0.5);
  color: #4A596D;
}
body .crypto-currency .crypto-currency-tags__button .info-blockchain__paragraph {
  color: #4A596D;
}
body .crypto-currency .crypto-currency-tags__button:hover {
  background-color: #2A59FF;
  color: #fff;
}
body .crypto-currency .crypto-currency-tags__button:hover i {
  color: #fff;
}
body .crypto-currency .top-content__part:not(:last-child) {
  border-right: 1px solid rgba(181, 186, 203, 0.3);
}
@media (max-width: 991.98px) {
  body .crypto-currency .top-content .crypto-currency-tags {
    border-right: 0;
  }
}
@media (max-width: 767.98px) {
  body .crypto-currency .top-content .crypto-currency-about {
    border-right: 0;
  }
}
@media (max-width: 1300px) {
  body .watchlist-product-name._fixed .watchlist-product-name__box {
    background-color: #fff;
  }
}
body .descr-card__screenshot {
  background-color: #fdfdfd;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
}

body .main__card-offer .card-offer__card {
  background-color: #f7faff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1E222A;
    color: #fff;
  }
  body .wrapper::before {
    opacity: 0;
  }
  body .wrapper::after {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist__header {
    border-top: 1px solid #1E222A;
  }
  body .main__watchlist .watchlist-title__title, body .main__watchlist .watchlist-showing__title {
    color: #8B98AA;
  }
  body .main__watchlist .watchlist-categories {
    border-left: 1px solid #323642;
    border-right: 1px solid #323642;
  }
  body .main__watchlist .watchlist-categories__el {
    color: #B5BACB;
  }
  body .main__watchlist .watchlist-categories__el:not(:last-child)::after {
    background-color: #323642;
  }
  body .main__watchlist .watchlist-categories__el_curent {
    color: #2A59FF;
  }
  body .main__watchlist .watchlist-showing::before {
    opacity: 0;
  }
  body .main__watchlist .watchlist-showing::after {
    opacity: 1;
  }
  body .main__watchlist .watchlist-showing .select__body {
    background-color: rgba(27, 32, 49, 0.95);
  }
  body .filter .select__item:hover,
  body .main__watchlist .watchlist-showing .select__item:hover {
    background-color: #2d3036;
  }
  body .pagination .show-rows .select__body {
    background-color: rgba(27, 32, 49, 0.95);
  }
  body .pagination .show-rows .select__item:hover {
    background-color: #2d3036;
  }
  body .main__watchlist .watchlist-filters__name {
    color: #B5BACB;
  }
  body .main__watchlist .watchlist-filters__button_filter {
    border: 1px solid rgba(69, 78, 83, 0.1490196078);
  }
  body .main__watchlist .watchlist-filters__button_filter::before {
    opacity: 1;
  }
  body .main__watchlist .watchlist-orientation__button {
    border: 1px solid #454E53;
  }
  body .main__watchlist .watchlist-orientation__button::before {
    opacity: 1;
  }
  body .main__watchlist .watchlist-orientation__button:not(.disabled):hover {
    border: 1px solid #2A59FF;
  }
  body .main__watchlist .select__body-wrapper::before {
    opacity: 0;
  }
  body .main__watchlist .select__body-wrapper::after {
    opacity: 1;
  }
  body .main__watchlist .watchlist-categories-spoiler {
    border-left: 1px solid #323642;
    border-right: 1px solid #323642;
  }
}
@media (prefers-color-scheme: dark) and (max-width: 767.98px) {
  body .main__watchlist .watchlist-categories-spoiler {
    border-right: 0px;
  }
}
@media (prefers-color-scheme: dark) {
  body .filter .select__item:hover,
  body .main__watchlist .watchlist-categories-spoiler .select__item:hover {
    background-color: #2d3036;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-main__header {
    background-color: rgba(41, 44, 45, 0.2);
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-legend:not(:nth-child(-n+2)), body .main__watchlist .watchlist-product__part:not(:nth-child(-n+2)) {
    border-left: 1px solid #1E222A;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-main::before {
    opacity: 0;
  }
  body .main__watchlist .watchlist-main::after {
    opacity: 1;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-product {
    border: 1px solid rgba(69, 78, 83, 0.2);
    background-color: #1e212a;
  }
  body .main__watchlist .watchlist-product:nth-child(2n) {
    background-color: #191C24;
  }
  body .main__watchlist .watchlist-product:nth-child(2n) .watchlist-product-name {
    background-color: #191C24;
  }
  body .main__watchlist .watchlist-product:hover .watchlist-product-name__full-name {
    color: #2A59FF;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-product-name {
    background-color: #1e212a;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-product__num {
    border: 1px solid rgba(69, 78, 83, 0.1490196078);
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-product-name__full-name {
    color: #fff;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-product-settings__button {
    border: 1px solid #454E53;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-product-settings__button span {
    background-color: #2A2E3A;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-product-settings__button._opened span {
    background-color: #2A59FF;
  }
}
@media (prefers-color-scheme: dark) {
  body .main__watchlist .watchlist-main._in-the-end::before, body .main__watchlist .watchlist-main._in-the-end::after {
    opacity: 0;
  }
}
@media (prefers-color-scheme: dark) {
  body .filter {
    background: rgba(27, 32, 49, 0.95);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
  }
  body .filter .select__body {
    background: rgba(27, 32, 49, 0.95);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
  }
  body .filter .filter__button_stroke {
    color: #fff;
  }
  body .filter .filter-search__input, body .filter .filter-range__input {
    color: #fff;
  }
}
@media (prefers-color-scheme: dark) {
  body .main-pagination__nav-btn {
    border: 1px solid rgba(69, 78, 83, 0.2);
  }
}
@media (prefers-color-scheme: dark) {
  body .main-pagination__nav-btn::before {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  body .watchlist_mod .watchlist-title::before {
    opacity: 1;
  }
  body .watchlist_mod .watchlist-title::after {
    opacity: 0;
  }
  body .watchlist_mod .watchlist-title .select__body {
    border: 0;
  }
  body .watchlist_mod .watchlist-showing {
    border-right: 0;
  }
  body .watchlist_mod .watchlist-showing:before, body .watchlist_mod .watchlist-showing::after {
    display: none;
  }
  body .watchlist_mod .watchlist-filters__button_filter._opened {
    border: 1px solid transparent;
  }
  body .watchlist_mod .watchlist-orientation__button._current {
    border: 1px solid transparent;
  }
  body .watchlist_mod .filter, body .watchlist_mod .filter__wrapper {
    background: transparent;
  }
  body .watchlist_mod .select__header, body .watchlist_mod .select__body, body .watchlist_mod .filter-search {
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  body .watchlist_mod .show-rows .select__header {
    border: 1px solid transparent;
  }
  body .watchlist_mod .show-rows .select__body {
    border: 0;
  }
  body .watchlist_mod .show-rows:hover .select__header {
    border: 1px solid #2A59FF;
  }
  body .watchlist .watchlist-product-status__status {
    background-color: #1E222A;
  }
}
@media (prefers-color-scheme: dark) {
  body .watchlist-main__list._grid .watchlist-product-nft {
    border-left: 0px;
  }
  body .watchlist-main__list._grid .watchlist-product-sale {
    border-left: 0;
  }
  body .watchlist-main__list._grid .watchlist-product-links {
    border-left: 0;
  }
  body .watchlist-main__list._grid .watchlist-product {
    background-color: #191C24;
    border-radius: 8px;
  }
  body .watchlist-main__list._grid .watchlist-product .watchlist-product-name {
    background-color: #191C24;
  }
}

@media (prefers-color-scheme: dark) {
  body .watchlist-product-more__button {
    border: 1px solid #454E53;
  }
}

@media (prefers-color-scheme: dark) {
  body .watchlist-showing__select .select__header {
    border: 1px solid transparent;
  }
}
@media (prefers-color-scheme: dark) {
  body .trends-navigation .main-trends__title {
    color: #fff;
  }
  body .trends-navigation .trends-navigation__select.is-active .trends-navigation__spoiler {
    border: 1px solid transparent;
  }
  body .trends-navigation .select__body {
    background-color: rgba(27, 32, 49, 0.9490196078);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
  }
}
@media (prefers-color-scheme: dark) {
  body .blockchain .blockchain-main {
    background-color: rgba(41, 44, 45, 0.2);
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  body .blockchain .blockchain-main__preview::before {
    opacity: 0;
  }
  body .blockchain .blockchain-main__preview::after {
    opacity: 1;
  }
  body .blockchain .info-blockchain__subtitle, body .blockchain .info-blockchain__paragraph, body .blockchain .blockchain-statistic__headline, body .blockchain .blockchain-statistic__label, body .blockchain .blockchain-grow__text, body .blockchain .blockchain-statistic__value, body .blockchain .card-info-blockchain__list, body .blockchain .card-info-blockchain__title, body .blockchain .card-info-blockchain__value, body .blockchain .attribute-card {
    color: #fff;
  }
  body .blockchain .blockchain-grow.plus .blockchain-grow__text {
    color: #71BA57;
  }
  body .blockchain .blockchain-grow.minus .blockchain-grow__text {
    color: #B14C6B;
  }
  body .blockchain .blockchain-statistic__part:not(:last-child) {
    border-right: 1px solid rgba(223, 227, 240, 0.1);
  }
  body .blockchain .blockchain-statistic {
    border-right: 1px solid rgba(223, 227, 240, 0.1);
    border-left: 1px solid rgba(223, 227, 240, 0.1);
  }
}
@media (prefers-color-scheme: dark) and (max-width: 991.98px) {
  body .blockchain .blockchain-statistic {
    border: 0;
    border-top: 1px solid rgba(223, 227, 240, 0.1);
  }
}
@media (prefers-color-scheme: dark) {
  body .blockchain .links-blockchain__button {
    background-color: rgba(41, 44, 45, 0.2);
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@media (prefers-color-scheme: dark) {
  body .blockchain .links-blockchain__text {
    color: #fff;
  }
}
@media (prefers-color-scheme: dark) and (max-width: 667.98px) {
  body .blockchain .links-blockchain {
    border-top: 1px solid rgba(223, 227, 240, 0.1);
  }
}
@media (prefers-color-scheme: dark) {
  body .blockchain .blockchain-body__card {
    background-color: rgba(41, 44, 45, 0.2);
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
    color: #fff;
  }
}
@media (prefers-color-scheme: dark) {
  body .section-button__button {
    background-color: #2A59FF;
    color: #fff;
  }
  body .section-button__button:hover {
    background-color: #1c3aa6;
    color: #fff;
  }
}
@media (prefers-color-scheme: dark) {
  body .also-watch-card {
    background-color: rgba(41, 44, 45, 0.2);
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
}
@media (prefers-color-scheme: dark) {
  body .also-watch-box::before {
    opacity: 0;
  }
  body .also-watch-box::after {
    opacity: 1;
  }
}
@media (prefers-color-scheme: dark) {
  body .also-watch-box._in-the-end::before, body .also-watch-box._in-the-end::after {
    opacity: 0;
  }
}
@media (prefers-color-scheme: dark) {
  body .also-watch::before {
    opacity: 0;
  }
  body .also-watch::after {
    opacity: 1;
  }
}
@media (prefers-color-scheme: dark) {
  body .watchlist_currency .watchlist-header {
    border-top: 0;
  }
}
@media (prefers-color-scheme: dark) {
  body .currency-info .currency-graph__content {
    background-color: rgba(41, 44, 45, 0.2);
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  body .currency-info .currency-graph__dark-img {
    display: block;
  }
  body .currency-info .currency-graph__light-img {
    display: none;
  }
  body .currency-info .currency-characteristic__el {
    border: 1px solid rgba(69, 78, 83, 0.2);
    background-color: rgba(55, 59, 62, 0.168627451);
    color: #fff;
  }
  body .currency-info .currency-characteristic__el:nth-child(2n) {
    background-color: rgba(41, 44, 45, 0.2);
  }
  body .currency-info .currency-characteristic__name {
    color: #fff;
  }
  body .currency-info .live-chat__chat {
    border: 1px solid rgba(69, 78, 83, 0.2);
    background-color: rgba(55, 59, 62, 0.168627451);
  }
  body .currency-info .post-comment__button {
    color: #fff;
  }
  body .currency-info .post-comment__button:hover {
    color: #2A59FF;
  }
  body .currency-info .user-live-chat__about {
    color: #fff;
  }
  body .currency-info .post-comment__area {
    background-color: rgba(47, 51, 54, 0.2);
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid rgba(69, 78, 83, 0.2);
  }
  body .currency-info .post-comment__input {
    color: #fff;
  }
  body .currency-info .comments-list .post-comment__comment {
    color: #fff;
  }
  body .currency-info .comments-list .post-comment__button_red:hover {
    color: #B14C6B;
  }
  body .currency-info .comments-list .post-comment__button_green:hover {
    color: #71BA57;
  }
  body .currency-info .comments-list::-webkit-scrollbar {
    background-color: #2A2E3A;
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency__box {
    background-color: rgba(41, 44, 45, 0.2);
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  body .crypto-currency .crypto-currency__paragraph, body .crypto-currency .links-list__text, body .crypto-currency .middle-content__title, body .crypto-currency .middle-content__price, body .crypto-currency .stat-middle-content__value, body .crypto-currency .stat-middle-content__label, body .crypto-currency .crypto-currency-converter__label, body .crypto-currency .crypto-currency-converter__input, body .crypto-currency .crypto-currency-about__subtitle, body .crypto-currency .about-range__labels {
    color: #fff;
  }
  body .crypto-currency .crypto-currency__paragraph a, body .crypto-currency .links-list__text a, body .crypto-currency .middle-content__title a, body .crypto-currency .middle-content__price a, body .crypto-currency .stat-middle-content__value a, body .crypto-currency .stat-middle-content__label a, body .crypto-currency .crypto-currency-converter__label a, body .crypto-currency .crypto-currency-converter__input a, body .crypto-currency .crypto-currency-about__subtitle a, body .crypto-currency .about-range__labels a {
    color: #fff;
  }
  body .crypto-currency .links-list__card {
    background-color: rgba(47, 51, 54, 0.2);
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid rgba(69, 78, 83, 0.2);
  }
  body .crypto-currency .middle-content__part:not(:nth-child(5)) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
}
@media (prefers-color-scheme: dark) and (max-width: 991.98px) {
  body .crypto-currency .middle-content__part:nth-child(4) {
    border: 0;
  }
  body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
  body .crypto-currency .middle-content__part:nth-child(6) {
    border-right: 0;
  }
}
@media (prefers-color-scheme: dark) and (max-width: 767.98px) {
  body .crypto-currency .middle-content__part:nth-child(3), body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 0;
  }
  body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
  body .crypto-currency .middle-content__part:nth-child(2) {
    border: 0;
  }
}
@media (prefers-color-scheme: dark) and (max-width: 480px) {
  body .crypto-currency .middle-content__part:first-child {
    border-right: 0;
    border-bottom: 1px solid rgba(181, 186, 203, 0.1);
  }
  body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 0;
    border-top: 1px solid rgba(181, 186, 203, 0.1);
  }
  body .crypto-currency .middle-content__part:nth-child(3) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency .middle-content {
    border-top: 1px solid rgba(181, 186, 203, 0.1);
    border-bottom: 1px solid rgba(181, 186, 203, 0.1);
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency .middle-content__wrapper::before {
    opacity: 0;
  }
  body .crypto-currency .middle-content__wrapper::after {
    opacity: 1;
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency .select__body {
    background-color: rgba(27, 32, 49, 0.95);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency .select__item:hover {
    background-color: #2d3036;
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency .crypto-currency-converter__body {
    background-color: rgba(47, 51, 54, 0.5019607843);
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid rgba(69, 78, 83, 0.2);
  }
}
@media (prefers-color-scheme: dark) and (max-width: 767.98px) {
  body .crypto-currency .supply {
    border-top: 1px solid rgba(181, 186, 203, 0.1);
    border-bottom: 1px solid rgba(181, 186, 203, 0.1);
  }
}
@media (prefers-color-scheme: dark) and (min-width: 480px) {
  body .crypto-currency .fully-cap::before, body .crypto-currency .crypto-currency-tags::before {
    opacity: 0;
  }
  body .crypto-currency .fully-cap::after, body .crypto-currency .crypto-currency-tags::after {
    opacity: 1;
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency .crypto-currency-tags__button {
    background-color: rgba(47, 51, 54, 0.5019607843);
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
    color: #fff;
  }
  body .crypto-currency .crypto-currency-tags__button i {
    color: #fff;
  }
  body .crypto-currency .crypto-currency-tags__button:hover {
    background-color: #2A59FF;
    color: #fff;
  }
  body .crypto-currency .crypto-currency-tags__button:hover i {
    color: #fff;
  }
}
@media (prefers-color-scheme: dark) {
  body .crypto-currency .top-content__part:not(:last-child) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
}
@media (prefers-color-scheme: dark) and (max-width: 991.98px) {
  body .crypto-currency .top-content .crypto-currency-tags {
    border-right: 0;
  }
}
@media (prefers-color-scheme: dark) and (max-width: 767.98px) {
  body .crypto-currency .top-content .crypto-currency-about {
    border-right: 0;
  }
}
@media (prefers-color-scheme: dark) and (max-width: 1300px) {
  body .watchlist-product-name._fixed .watchlist-product-name__box {
    background-color: #1E222A;
  }
}
@media (prefers-color-scheme: dark) {
  body .descr-card__screenshot {
    background-color: rgba(41, 44, 45, 0.2);
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}

@media (prefers-color-scheme: dark) {
  body .main__card-offer .card-offer__card {
    background-color: rgba(41, 44, 45, 0.2);
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
}

html.dark body {
  background-color: #1E222A;
  color: #fff;
}
html.dark body .wrapper::before {
  opacity: 0;
}
html.dark body .wrapper::after {
  opacity: 1;
}

html.dark body .main__watchlist .watchlist__header {
  border-top: 1px solid #1E222A;
}
html.dark body .main__watchlist .watchlist-title__title, html.dark body .main__watchlist .watchlist-showing__title {
  color: #8B98AA;
}
html.dark body .main__watchlist .watchlist-categories {
  border-left: 1px solid #323642;
  border-right: 1px solid #323642;
}
html.dark body .main__watchlist .watchlist-categories__el {
  color: #B5BACB;
}
html.dark body .main__watchlist .watchlist-categories__el:not(:last-child)::after {
  background-color: #323642;
}
html.dark body .main__watchlist .watchlist-categories__el_curent {
  color: #2A59FF;
}
html.dark body .main__watchlist .watchlist-showing::before {
  opacity: 0;
}
html.dark body .main__watchlist .watchlist-showing::after {
  opacity: 1;
}
html.dark body .main__watchlist .watchlist-showing .select__body {
  background-color: rgba(27, 32, 49, 0.95);
}
body .filter .select__item:hover,
html.dark body .main__watchlist .watchlist-showing .select__item:hover {
  background-color: #2d3036;
}
html.dark body .pagination .show-rows .select__body {
  background-color: rgba(27, 32, 49, 0.95);
}
html.dark body .pagination .show-rows .select__item:hover {
  background-color: #2d3036;
}
html.dark body .main__watchlist .watchlist-filters__name {
  color: #B5BACB;
}
html.dark body .main__watchlist .watchlist-filters__button_filter {
  border: 1px solid rgba(69, 78, 83, 0.1490196078);
}
html.dark body .main__watchlist .watchlist-filters__button_filter::before {
  opacity: 1;
}
html.dark body .main__watchlist .watchlist-orientation__button {
  border: 1px solid #454E53;
}
html.dark body .main__watchlist .watchlist-orientation__button::before {
  opacity: 1;
}
html.dark body .main__watchlist .watchlist-orientation__button:not(.disabled):hover {
  border: 1px solid #2A59FF;
}
html.dark body .main__watchlist .select__body-wrapper::before {
  opacity: 0;
}
html.dark body .main__watchlist .select__body-wrapper::after {
  opacity: 1;
}
html.dark body .main__watchlist .watchlist-categories-spoiler {
  border-left: 1px solid #323642;
  border-right: 1px solid #323642;
}
@media (max-width: 767.98px) {
  html.dark body .main__watchlist .watchlist-categories-spoiler {
    border-right: 0px;
  }
}
html.dark body .main__watchlist .watchlist-categories-spoiler .select__item:hover {
  background-color: #2d3036;
}
html.dark body .main__watchlist .watchlist-main__header {
  background-color: rgba(41, 44, 45, 0.2);
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .main__watchlist .watchlist-legend:not(:nth-child(-n+2)), html.dark body .main__watchlist .watchlist-product__part:not(:nth-child(-n+2)) {
  border-left: 1px solid #1E222A;
}
html.dark body .main__watchlist .watchlist-main::before {
  opacity: 0;
}
html.dark body .main__watchlist .watchlist-main::after {
  opacity: 1;
}
html.dark body .main__watchlist .watchlist-product {
  border: 1px solid rgba(69, 78, 83, 0.2);
  background-color: #1e212a;
}
html.dark body .main__watchlist .watchlist-product:nth-child(2n) {
  background-color: #191C24;
}
html.dark body .main__watchlist .watchlist-product:nth-child(2n) .watchlist-product-name {
  background-color: #191C24;
}
html.dark body .main__watchlist .watchlist-product:hover .watchlist-product-name__full-name {
  color: #2A59FF;
}
html.dark body .main__watchlist .watchlist-product-name {
  background-color: #1e212a;
}
html.dark body .main__watchlist .watchlist-product__num {
  border: 1px solid rgba(69, 78, 83, 0.1490196078);
}
html.dark body .main__watchlist .watchlist-product-name__full-name {
  color: #fff;
}
html.dark body .main__watchlist .watchlist-product-settings__button {
  border: 1px solid #454E53;
}
html.dark body .main__watchlist .watchlist-product-settings__button._opened span {
  background-color: #2A59FF;
}
html.dark body .main__watchlist .watchlist-main._in-the-end::before, html.dark body .main__watchlist .watchlist-main._in-the-end::after {
  opacity: 0;
}
html.dark body .filter {
  background: rgba(27, 32, 49, 0.95);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.dark body .filter .select__body {
  background: rgba(27, 32, 49, 0.95);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.dark body .filter .filter__button_stroke {
  color: #fff;
}
html.dark body .filter .filter-search__input, html.dark body .filter .filter-range__input {
  color: #fff;
}
html.dark body .main-pagination__nav-btn {
  border: 1px solid rgba(69, 78, 83, 0.2);
}
html.dark body .main-pagination__nav-btn::before {
  opacity: 1;
}


html.dark body .watchlist_mod .watchlist-title::before {
  opacity: 1;
}
html.dark body .watchlist_mod .watchlist-title::after {
  opacity: 0;
}
html.dark body .watchlist_mod .watchlist-showing {
  border-right: 0;
}
html.dark body .watchlist_mod .watchlist-showing:before, html.dark body .watchlist_mod .watchlist-showing::after {
  display: none;
}
html.dark body .watchlist_mod .watchlist-showing .select__body {
  border: 0;
}
html.dark body .watchlist_mod .watchlist-filters__button_filter._opened {
  border: 1px solid transparent;
}
html.dark body .watchlist_mod .watchlist-orientation__button._current {
  border: 1px solid transparent;
}
html.dark body .watchlist_mod .filter, html.dark body .watchlist_mod .filter__wrapper {
  background: transparent;
}
html.dark body .watchlist_mod .select__header, html.dark body .watchlist_mod .select__body, html.dark body .watchlist_mod .filter-search {
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .watchlist_mod .show-rows .select__header {
  border: 1px solid transparent;
}
html.dark body .watchlist_mod .show-rows .select__body {
  border: 0;
}
html.dark body .watchlist_mod .show-rows:hover .select__header {
  border: 1px solid #2A59FF;
}
html.dark body .watchlist-main__list._grid .watchlist-product-nft {
  border-left: 0px;
}
html.dark body .watchlist-main__list._grid .watchlist-product-sale {
  border-left: 0;
}
html.dark body .watchlist-main__list._grid .watchlist-product-links {
  border-left: 0;
}
html.dark body .watchlist-main__list._grid .watchlist-product {
  background-color: #191C24;
  border-radius: 8px;
}
html.dark body .watchlist-main__list._grid .watchlist-product .watchlist-product-name {
  background-color: #191C24;
}

html.dark body .watchlist-product-more__button {
  color: #fff;
  border: 1px solid #454E53;
}

html.dark body .watchlist-showing__select .select__header {
  border: 1px solid transparent;
}
html.dark body .trends-navigation .main-trends__title {
  color: #fff;
}
html.dark body .trends-navigation .trends-navigation__select.is-active .trends-navigation__spoiler {
  border: 1px solid transparent;
}
html.dark body .trends-navigation .select__body {
  background-color: rgba(27, 32, 49, 0.9490196078);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.dark body .blockchain .blockchain-main {
  background-color: rgba(41, 44, 45, 0.2);
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .blockchain .blockchain-main__preview::before {
  opacity: 0;
}
html.dark body .blockchain .blockchain-main__preview::after {
  opacity: 1;
}
html.dark body .blockchain .info-blockchain__subtitle, html.dark body .blockchain .info-blockchain__paragraph, html.dark body .blockchain .blockchain-statistic__headline, html.dark body .blockchain .blockchain-statistic__label, html.dark body .blockchain .blockchain-grow__text, html.dark body .blockchain .blockchain-statistic__value, html.dark body .blockchain .card-info-blockchain__list, html.dark body .blockchain .card-info-blockchain__title, html.dark body .blockchain .card-info-blockchain__value, html.dark body .blockchain .attribute-card {
  color: #fff;
}
html.dark body .blockchain .blockchain-grow.plus .blockchain-grow__text {
  color: #71BA57;
}
html.dark body .blockchain .blockchain-grow.minus .blockchain-grow__text {
  color: #B14C6B;
}
html.dark body .blockchain .blockchain-statistic__part:not(:last-child) {
  border-right: 1px solid rgba(223, 227, 240, 0.1);
}
html.dark body .blockchain .blockchain-statistic {
  border-right: 1px solid rgba(223, 227, 240, 0.1);
  border-left: 1px solid rgba(223, 227, 240, 0.1);
}
@media (max-width: 991.98px) {
  html.dark body .blockchain .blockchain-statistic {
    border: 0;
    border-top: 1px solid rgba(223, 227, 240, 0.1);
  }
}
html.dark body .blockchain .links-blockchain__button {
  background-color: rgba(41, 44, 45, 0.2);
  -webkit-box-shadow: none;
          box-shadow: none;
}
html.dark body .blockchain .links-blockchain__text {
  color: #fff;
}
@media (max-width: 667.98px) {
  html.dark body .blockchain .links-blockchain {
    border-top: 1px solid rgba(223, 227, 240, 0.1);
  }
}
html.dark body .blockchain .blockchain-body__card {
  background-color: rgba(41, 44, 45, 0.2);
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .section-button__button {
  background-color: #2A59FF;
  color: #fff;
}
html.dark body .section-button__button:hover {
  background-color: #1c3aa6;
  color: #fff;
}
html.dark body .also-watch-card {
  background-color: rgba(41, 44, 45, 0.2);
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .also-watch-box::before {
  opacity: 0;
}
html.dark body .also-watch-box::after {
  opacity: 1;
}
html.dark body .also-watch-box._in-the-end::before, html.dark body .also-watch-box._in-the-end::after {
  opacity: 0;
}
html.dark body .also-watch::before {
  opacity: 0;
}
html.dark body .also-watch::after {
  opacity: 1;
}
html.dark body .watchlist_currency .watchlist-header {
  border-top: 0;
}
html.dark body .currency-info .currency-graph__content {
  background-color: rgba(41, 44, 45, 0.2);
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .currency-info .currency-graph__dark-img {
  display: block;
}
html.dark body .currency-info .currency-graph__light-img {
  display: none;
}
html.dark body .currency-info .currency-characteristic__el {
  border: 1px solid rgba(69, 78, 83, 0.2);
  background-color: rgba(55, 59, 62, 0.168627451);
  color: #fff;
}
html.dark body .currency-info .currency-characteristic__el:nth-child(2n) {
  background-color: rgba(41, 44, 45, 0.2);
}
html.dark body .currency-info .currency-characteristic__name {
  color: #fff;
}
html.dark body .currency-info .live-chat__chat {
  border: 1px solid rgba(69, 78, 83, 0.2);
  background-color: rgba(55, 59, 62, 0.168627451);
}
html.dark body .currency-info .post-comment__button {
  color: #fff;
}
html.dark body .currency-info .post-comment__button:hover {
  color: #2A59FF;
}
html.dark body .currency-info .user-live-chat__about {
  color: #fff;
}
html.dark body .currency-info .post-comment__area {
  background-color: rgba(47, 51, 54, 0.2);
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(69, 78, 83, 0.2);
}
html.dark body .currency-info .post-comment__input {
  color: #fff;
}
html.dark body .currency-info .comments-list .post-comment__comment {
  color: #fff;
}
html.dark body .currency-info .comments-list .post-comment__button_red:hover {
  color: #B14C6B;
}
html.dark body .currency-info .comments-list .post-comment__button_green:hover {
  color: #71BA57;
}
html.dark body .currency-info .comments-list::-webkit-scrollbar {
  background-color: #2A2E3A;
}
html.dark body .crypto-currency__box {
  background-color: rgba(41, 44, 45, 0.2);
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .crypto-currency .crypto-currency__paragraph, html.dark body .crypto-currency .links-list__text, html.dark body .crypto-currency .middle-content__title, html.dark body .crypto-currency .middle-content__price, html.dark body .crypto-currency .stat-middle-content__value, html.dark body .crypto-currency .stat-middle-content__label, html.dark body .crypto-currency .crypto-currency-converter__label, html.dark body .crypto-currency .crypto-currency-converter__input, html.dark body .crypto-currency .crypto-currency-about__subtitle, html.dark body .crypto-currency .about-range__labels {
  color: #fff;
}
html.dark body .crypto-currency .crypto-currency__paragraph a, html.dark body .crypto-currency .links-list__text a, html.dark body .crypto-currency .middle-content__title a, html.dark body .crypto-currency .middle-content__price a, html.dark body .crypto-currency .stat-middle-content__value a, html.dark body .crypto-currency .stat-middle-content__label a, html.dark body .crypto-currency .crypto-currency-converter__label a, html.dark body .crypto-currency .crypto-currency-converter__input a, html.dark body .crypto-currency .crypto-currency-about__subtitle a, html.dark body .crypto-currency .about-range__labels a {
  color: #fff;
}
html.dark body .crypto-currency .links-list__card {
  background-color: rgba(47, 51, 54, 0.2);
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(69, 78, 83, 0.2);
}
html.dark body .crypto-currency .middle-content__part:not(:nth-child(5)) {
  border-right: 1px solid rgba(181, 186, 203, 0.1);
}
@media (max-width: 991.98px) {
  html.dark body .crypto-currency .middle-content__part:nth-child(4) {
    border: 0;
  }
  html.dark body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
  html.dark body .crypto-currency .middle-content__part:nth-child(6) {
    border-right: 0;
  }
}
@media (max-width: 767.98px) {
  html.dark body .crypto-currency .middle-content__part:nth-child(3), html.dark body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 0;
  }
  html.dark body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
  html.dark body .crypto-currency .middle-content__part:nth-child(2) {
    border: 0;
  }
}
@media (max-width: 480px) {
  html.dark body .crypto-currency .middle-content__part:first-child {
    border-right: 0;
  }
  html.dark body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 0;
    border-top: 1px solid rgba(181, 186, 203, 0.1);
  }
  html.dark body .crypto-currency .middle-content__part:nth-child(3) {
    border-right: 1px solid rgba(181, 186, 203, 0.1);
  }
}
html.dark body .crypto-currency .middle-content {
  border-top: 1px solid rgba(181, 186, 203, 0.1);
  border-bottom: 1px solid rgba(181, 186, 203, 0.1);
}
html.dark body .crypto-currency .middle-content__wrapper::before {
  opacity: 0;
}
html.dark body .crypto-currency .middle-content__wrapper::after {
  opacity: 1;
}
html.dark body .crypto-currency .select__body {
  background-color: rgba(27, 32, 49, 0.95);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.dark body .crypto-currency .select__item:hover {
  background-color: #2d3036;
}
html.dark body .crypto-currency .crypto-currency-converter__body {
  background-color: rgba(47, 51, 54, 0.5019607843);
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(69, 78, 83, 0.2);
}
@media (max-width: 767.98px) {
  html.dark body .crypto-currency .supply {
    border-top: 1px solid rgba(181, 186, 203, 0.1);
    border-bottom: 1px solid rgba(181, 186, 203, 0.1);
  }
}
@media (min-width: 480px) {
  html.dark body .crypto-currency .fully-cap::before, html.dark body .crypto-currency .crypto-currency-tags::before {
    opacity: 0;
  }
  html.dark body .crypto-currency .fully-cap::after, html.dark body .crypto-currency .crypto-currency-tags::after {
    opacity: 1;
  }
}
html.dark body .crypto-currency .crypto-currency-tags__button {
  background-color: rgba(47, 51, 54, 0.5019607843);
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
  color: #fff;
}
html.dark body .crypto-currency .crypto-currency-tags__button i {
  color: #fff;
}
html.dark body .crypto-currency .crypto-currency-tags__button:hover {
  background-color: #2A59FF;
  color: #fff;
}
html.dark body .crypto-currency .crypto-currency-tags__button:hover i {
  color: #fff;
}
html.dark body .crypto-currency .top-content__part:not(:last-child) {
  border-right: 1px solid rgba(181, 186, 203, 0.1);
}
@media (max-width: 991.98px) {
  html.dark body .crypto-currency .top-content .crypto-currency-tags {
    border-right: 0;
  }
}
@media (max-width: 767.98px) {
  html.dark body .crypto-currency .top-content .crypto-currency-about {
    border-right: 0;
  }
}
@media (max-width: 1300px) {
  html.dark body .watchlist-product-name._fixed .watchlist-product-name__box {
    background-color: #1E222A;
  }
}
html.dark body .descr-card__screenshot {
  background-color: rgba(41, 44, 45, 0.2);
  -webkit-box-shadow: none;
          box-shadow: none;
}

html.dark body .main__card-offer .card-offer__card {
  background-color: rgba(41, 44, 45, 0.2);
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.light body {
  background-color: #E8EBEF;
  color: #1E222A;
}
html.light body .wrapper::before {
  opacity: 1;
}
html.light body .wrapper::after {
  opacity: 0;
}

html.light body .main__watchlist .watchlist__header {
  border-top: 1px solid #B5BACB;
}
html.light body .main__watchlist .watchlist-title__title, html.light body .main__watchlist .watchlist-showing__title {
  color: #1E222A;
}
html.light body .main__watchlist .watchlist-categories {
  border-left: 1px solid #B5BACB;
  border-right: 1px solid #B5BACB;
}
html.light body .main__watchlist .watchlist-categories__el {
  color: #1E222A;
}
html.light body .main__watchlist .watchlist-categories__el:not(:last-child)::after {
  background-color: #B5BACB;
}
html.light body .main__watchlist .watchlist-categories__el_curent {
  color: #2A59FF;
}
html.light body .main__watchlist .watchlist-showing::before {
  opacity: 1;
}
html.light body .main__watchlist .watchlist-showing::after {
  opacity: 0;
}
html.light body .main__watchlist .watchlist-showing .select__body {
  background-color: rgba(243, 247, 251, 0.9);
}
body .filter .select__item:hover,
html.light body .main__watchlist .watchlist-showing .select__item:hover {
  background-color: #e9e9e9;
}
html.light body .pagination .show-rows .select__body {
  background-color: rgba(243, 247, 251, 0.9);
}
html.light body .pagination .show-rows .select__item:hover {
  background-color: #e9e9e9;
}
html.light body .main__watchlist .watchlist-filters__name {
  color: #1E222A;
}
html.light body .main__watchlist .watchlist-filters__button_filter {
  border: 1px solid #B5BACB;
}
html.light body .main__watchlist .watchlist-filters__button_filter::before {
  opacity: 0;
}
html.light body .main__watchlist .watchlist-orientation__button {
  border: 1px solid #B5BACB;
}
html.light body .main__watchlist .watchlist-orientation__button::before {
  opacity: 0;
}
html.light body .main__watchlist .watchlist-orientation__button:not(.disabled):hover {
  border: 1px solid #2A59FF;
}
html.light body .main__watchlist .select__body-wrapper::before {
  opacity: 1;
}
html.light body .main__watchlist .select__body-wrapper::after {
  opacity: 0;
}
html.light body .main__watchlist .watchlist-categories-spoiler {
  border-left: 1px solid #B5BACB;
  border-right: 1px solid #B5BACB;
}
@media (max-width: 767.98px) {
  html.light body .main__watchlist .watchlist-categories-spoiler {
    border-right: 0px;
  }
}
html.light body .main__watchlist .watchlist-categories-spoiler .select__item:hover {
  background-color: #e9e9e9;
}
html.light body .main__watchlist .watchlist-main__header {
  background-color: #f7faff;
  border: 1px solid transparent;
}
html.light body .main__watchlist .watchlist-legend:not(:nth-child(-n+2)), html.light body .main__watchlist .watchlist-product__part:not(:nth-child(-n+2)) {
  border-left: 1px solid #DFE3F0;
}
html.light body .main__watchlist .watchlist-main::before {
  opacity: 1;
}
html.light body .main__watchlist .watchlist-main::after {
  opacity: 0;
}
html.light body .main__watchlist .watchlist-product {
  border: 1px solid transparent;
  background-color: #f2f4fd;
}
html.light body .main__watchlist .watchlist-product:nth-child(2n) {
  background-color: #f7faff;
}
html.light body .main__watchlist .watchlist-product:nth-child(2n) .watchlist-product-name {
  background-color: #f7faff;
}
html.light body .main__watchlist .watchlist-product:hover .watchlist-product-name__full-name {
  color: #2A59FF;
}
html.light body .main__watchlist .watchlist-product-name {
  background-color: rgb(242, 244, 253);
}
html.light body .main__watchlist .watchlist-product__num {
  border: 1px solid #DFE3F0;
}
html.light body .main__watchlist .watchlist-product-name__full-name {
  color: #1E222A;
}
html.light body .main__watchlist .watchlist-product-settings__button {
  border: 1px solid #B5BACB;
}
html.light body .main__watchlist .watchlist-product-settings__button span {
  background-color: #2A59FF;
}
html.light body .main__watchlist .watchlist-product-settings__button._opened span {
  background-color: #2A59FF;
}
html.light body .main__watchlist .watchlist-main._in-the-end::before, html.light body .main__watchlist .watchlist-main._in-the-end::after {
  opacity: 0;
}
html.light body .filter {
  background: rgba(243, 247, 251, 0.9);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.light body .filter .select__body {
  background: #F2F6FA;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.light body .filter .filter__button_stroke {
  color: #1E222A;
}
html.light body .filter .filter-search__input, html.light body .filter .filter-range__input {
  color: #1E222A;
}
html.light body .main-pagination__nav-btn {
  border: 1px solid #B5BACB;
}
html.light body .main-pagination__nav-btn::before {
  opacity: 0;
}

html.light body .watchlist_mod .watchlist-title::before {
  opacity: 1;
}
html.light body .watchlist_mod .watchlist-title::after {
  opacity: 0;
}
html.light body .watchlist_mod .watchlist-showing {
  border-right: 0;
}
html.light body .watchlist_mod .watchlist-showing:before, html.light body .watchlist_mod .watchlist-showing::after {
  display: none;
}
html.light body .watchlist_mod .watchlist-showing .select__body {
  border: 0;
}
html.light body .watchlist_mod .watchlist-filters__button_filter._opened {
  border: 1px solid transparent;
}
html.light body .watchlist_mod .watchlist-orientation__button._current {
  border: 1px solid transparent;
}
html.light body .watchlist_mod .filter, html.light body .watchlist_mod .filter__wrapper {
  background: transparent;
}
html.light body .watchlist_mod .select__header, html.light body .watchlist_mod .select__body, html.light body .watchlist_mod .filter-search {
  border: 1px solid #B5BACB;
}
html.light body .watchlist_mod .show-rows .select__header {
  border: 1px solid transparent;
}
html.light body .watchlist_mod .show-rows .select__body {
  border: 0;
}
html.light body .watchlist_mod .show-rows:hover .select__header {
  border: 1px solid #2A59FF;
}
html.light body .watchlist .watchlist-product-status__status {
  background-color: #DFE4F0;
}
html.light body .watchlist-main__list._grid .watchlist-product-nft {
  border-left: 0px;
}
html.light body .watchlist-main__list._grid .watchlist-product-sale {
  border-left: 0;
}
html.light body .watchlist-main__list._grid .watchlist-product-links {
  border-left: 0;
}
html.light body .watchlist-main__list._grid .watchlist-product {
  background-color: #f7faff;
  border-radius: 8px;
}
html.light body .watchlist-main__list._grid .watchlist-product .watchlist-product-name {
  background-color: #f7faff;
}
html.light body .watchlist-product-more__button {
  border: 1px solid #B5BACB;
}
html.light body .watchlist-showing__select .select__header {
  border: 1px solid transparent;
}
html.light body .trends-navigation .main-trends__title {
  color: #1E222A;
}
html.light body .trends-navigation .trends-navigation__select.is-active .trends-navigation__spoiler {
  border: 1px solid transparent;
}
html.light body .trends-navigation .select__body {
  background-color: rgba(243, 247, 251, 0.8980392157);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.light body .blockchain .blockchain-main {
  background-color: #f7faff;
  border: 1px solid transparent;
}
html.light body .blockchain .blockchain-main__preview::before {
  opacity: 1;
}
html.light body .blockchain .blockchain-main__preview::after {
  opacity: 0;
}
html.light body .blockchain .info-blockchain__subtitle, html.light body .blockchain .info-blockchain__paragraph, html.light body .blockchain .blockchain-statistic__headline, html.light body .blockchain .blockchain-statistic__label, html.light body .blockchain .blockchain-grow__text, html.light body .blockchain .blockchain-statistic__value, html.light body .blockchain .card-info-blockchain__list, html.light body .blockchain .card-info-blockchain__title, html.light body .blockchain .card-info-blockchain__value, html.light body .blockchain .attribute-card {
  color: #4A596D;
}
html.light body .blockchain .blockchain-grow.plus .blockchain-grow__text {
  color: #71BA57;
}
html.light body .blockchain .blockchain-grow.minus .blockchain-grow__text {
  color: #B14C6B;
}
html.light body .blockchain .blockchain-statistic__part:not(:last-child) {
  border-right: 1px solid rgba(181, 186, 203, 0.5);
}
html.light body .blockchain .blockchain-statistic {
  border-right: 1px solid rgba(181, 186, 203, 0.5);
  border-left: 1px solid rgba(181, 186, 203, 0.5);
}
@media (max-width: 991.98px) {
  html.light body .blockchain .blockchain-statistic {
    border: 0;
    border-top: 1px solid rgba(181, 186, 203, 0.5);
  }
}
html.light body .blockchain .links-blockchain__button {
  background-color: #f7faff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
}
html.light body .blockchain .links-blockchain__text {
  color: #55606F;
}
@media (max-width: 667.98px) {
  html.light body .blockchain .links-blockchain {
    border-top: 1px solid rgba(181, 186, 203, 0.5);
  }
}
html.light body .blockchain .blockchain-body__card {
  background-color: #f7faff;
  border: 1px solid transparent;
}
html.light body .section-button__button {
  background-color: #fff;
  color: #4A596D;
}
html.light body .section-button__button:hover {
  background-color: #ddd;
  color: #515764;
}
html.light body .also-watch-card {
  background-color: #f7faff;
  border: 1px solid rgba(197, 199, 207, 0.7);
}
html.light body .also-watch-box::before {
  opacity: 1;
}
html.light body .also-watch-box::after {
  opacity: 0;
}
html.light body .also-watch-box._in-the-end::before, html.light body .also-watch-box._in-the-end::after {
  opacity: 0;
}
html.light body .also-watch::before {
  opacity: 1;
}
html.light body .also-watch::after {
  opacity: 0;
}
html.light body .watchlist_currency .watchlist-header {
  border-top: 0;
}
html.light body .currency-info .currency-graph__content {
  background-color: #f7faff;
  border: 1px solid transparent;
}
html.light body .currency-info .currency-graph__dark-img {
  display: none;
}
html.light body .currency-info .currency-graph__light-img {
  display: block;
}
html.light body .currency-info .currency-characteristic__el {
  border: 1px solid transparent;
  background-color: #f2f4fd;
  color: #4A596D;
}
html.light body .currency-info .currency-characteristic__el:nth-child(2n) {
  background-color: #f7faff;
}
html.light body .currency-info .currency-characteristic__name {
  color: #4A596D;
}
html.light body .currency-info .live-chat__chat {
  background-color: #f3f6fb;
  border: 1px solid transparent;
}
html.light body .currency-info .post-comment__button {
  color: #4A596D;
}
html.light body .currency-info .post-comment__button:hover {
  color: #2A59FF;
}
html.light body .currency-info .user-live-chat__about {
  color: #4A596D;
}
html.light body .currency-info .post-comment__area {
  background-color: #f5f8ff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}
html.light body .currency-info .post-comment__input {
  color: #4A596D;
}
html.light body .currency-info .comments-list .post-comment__comment {
  color: #4A596D;
}
html.light body .currency-info .comments-list .post-comment__button_red:hover {
  color: #B14C6B;
}
html.light body .currency-info .comments-list .post-comment__button_green:hover {
  color: #71BA57;
}
html.light body .currency-info .comments-list::-webkit-scrollbar {
  background-color: #fff;
}
html.light body .crypto-currency__box {
  background-color: #f7faff;
  border: 1px solid transparent;
}
html.light body .crypto-currency .crypto-currency__paragraph, html.light body .crypto-currency .links-list__text, html.light body .crypto-currency .middle-content__title, html.light body .crypto-currency .middle-content__price, html.light body .crypto-currency .stat-middle-content__value, html.light body .crypto-currency .stat-middle-content__label, html.light body .crypto-currency .crypto-currency-converter__label, html.light body .crypto-currency .crypto-currency-converter__input, html.light body .crypto-currency .crypto-currency-about__subtitle, html.light body .crypto-currency .about-range__labels {
  color: #4A596D;
}
html.light body .crypto-currency .crypto-currency__paragraph a, html.light body .crypto-currency .links-list__text a, html.light body .crypto-currency .middle-content__title a, html.light body .crypto-currency .middle-content__price a, html.light body .crypto-currency .stat-middle-content__value a, html.light body .crypto-currency .stat-middle-content__label a, html.light body .crypto-currency .crypto-currency-converter__label a, html.light body .crypto-currency .crypto-currency-converter__input a, html.light body .crypto-currency .crypto-currency-about__subtitle a, html.light body .crypto-currency .about-range__labels a {
  color: #4A596D;
}
html.light body .crypto-currency .links-list__card {
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}
html.light body .crypto-currency .middle-content__part:not(:nth-child(5)) {
  border-right: 1px solid rgba(181, 186, 203, 0.3);
}
@media (max-width: 991.98px) {
  html.light body .crypto-currency .middle-content__part:nth-child(4) {
    border: 0;
  }
  html.light body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 1px solid rgba(181, 186, 203, 0.3);
  }
  html.light body .crypto-currency .middle-content__part:nth-child(6) {
    border-right: 0;
  }
}
@media (max-width: 767.98px) {
  html.light body .crypto-currency .middle-content__part:nth-child(3), html.light body .crypto-currency .middle-content__part:nth-child(5) {
    border-right: 0;
  }
  html.light body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 1px solid rgba(181, 186, 203, 0.3);
  }
  html.light body .crypto-currency .middle-content__part:nth-child(2) {
    border: 0;
  }
}
@media (max-width: 480px) {
  html.light body .crypto-currency .middle-content__part:first-child {
    border-right: 0;
    border-bottom: 1px solid rgba(181, 186, 203, 0.3);
  }
  html.light body .crypto-currency .middle-content__part:nth-child(4) {
    border-right: 0;
    border-top: 1px solid rgba(181, 186, 203, 0.3);
  }
  html.light body .crypto-currency .middle-content__part:nth-child(3) {
    border-right: 1px solid rgba(181, 186, 203, 0.3);
  }
}
html.light body .crypto-currency .middle-content {
  border-top: 1px solid rgba(181, 186, 203, 0.3);
  border-bottom: 1px solid rgba(181, 186, 203, 0.3);
}
html.light body .crypto-currency .middle-content__wrapper::before {
  opacity: 1;
}
html.light body .crypto-currency .middle-content__wrapper::after {
  opacity: 0;
}
html.light body .crypto-currency .select__body {
  background-color: rgba(243, 247, 251, 0.9);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
html.light body .crypto-currency .select__item:hover {
  background-color: #e9e9e9;
}
html.light body .crypto-currency .crypto-currency-converter__body {
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}
@media (max-width: 767.98px) {
  html.light body .crypto-currency .supply {
    border-top: 1px solid rgba(181, 186, 203, 0.3);
    border-bottom: 1px solid rgba(181, 186, 203, 0.3);
  }
}
@media (min-width: 480px) {
  html.light body .crypto-currency .fully-cap::before, html.light body .crypto-currency .crypto-currency-tags::before {
    opacity: 1;
  }
  html.light body .crypto-currency .fully-cap::after, html.light body .crypto-currency .crypto-currency-tags::after {
    opacity: 0;
  }
}
html.light body .crypto-currency .crypto-currency-tags__button {
  background-color: transparent;
  border: 1px solid rgba(181, 186, 203, 0.5);
  color: #4A596D;
}
html.light body .crypto-currency .crypto-currency-tags__button i {
  color: #4A596D;
}
html.light body .crypto-currency .crypto-currency-tags__button:hover {
  background-color: #2A59FF;
  color: #fff;
}
html.light body .crypto-currency .crypto-currency-tags__button:hover i {
  color: #fff;
}
html.light body .crypto-currency .top-content__part:not(:last-child) {
  border-right: 1px solid rgba(181, 186, 203, 0.3);
}
@media (max-width: 991.98px) {
  html.light body .crypto-currency .top-content .crypto-currency-tags {
    border-right: 0;
  }
}
@media (max-width: 767.98px) {
  html.light body .crypto-currency .top-content .crypto-currency-about {
    border-right: 0;
  }
}
html.light body .main__card-offer .card-offer__card {
  background-color: #f7faff;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border: 1px solid transparent;
}

.filter .select__header {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 35px;
  border-radius: 6px;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
  padding: 0 10px;
}
.filter .select__current {
  pointer-events: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #55606F;
}
.filter .select__icon i {
  font-size: .5rem;
  -webkit-transition: -webkit-transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.filter .select__icon i {
  color: #2A59FF;
}
.filter .select__body {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  max-height: 20rem;
  border-radius: 6px;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  padding: 12px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 12px;
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  -webkit-transition: opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.filter .select__item {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #55606F;
  cursor: pointer;
}

.filter-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  border-radius: 10px;
  padding-left: 4px;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
.filter-search__icon {
  margin: 0 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
}
.filter-search__input {
  background-color: transparent;
  height: 100%;
  width: 100%;
}

.filter-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 8px;
}
.filter-select__label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #55606F;
}
.filter-select__select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}

.filter-select__select.is-active {
  z-index: 100;
}

.main__watchlist {
  margin-bottom: 10px;
}

.watchlist-product-name {
  padding-left: 0;
  position: sticky;
  left: -10px;
  width: auto;
  z-index: 2;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}
.watchlist-product-name__box {
  padding-left: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  position: relative;
}
.watchlist-product-name__box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(28, 49, 109, 0) -73.23%, rgba(2, 10, 196, 0.06) 91.58%);
  -webkit-filter: drop-shadow(0px 4px 4px rgba(30, 33, 48, 0.11));
          filter: drop-shadow(0px 4px 4px rgba(30, 33, 48, 0.11));
  border-radius: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.grid-mod .watchlist-product-name__box::before {
  border-radius: 4px
}
.watchlist-product-name__icon {
  margin-right: 8px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.watchlist-product-name__icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.watchlist-product-name__name {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #515764;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 7px;
}
.watchlist-product-name__full-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.watchlist-product-name__full-name i {
  margin-right: .25rem;
}

.watchlist__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 1300px) {
  .watchlist__container {
    padding: 0;
  }
}
.watchlist__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  z-index: 31;
  padding: 18px 0;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
@media (max-width: 1300px) {
  .watchlist__header {
    margin: 0 10px;
    width: auto;
  }
}
@media (max-width: 767.98px) {
  .watchlist__header {
    padding-top: calc(9px + 9 * (100vw - 320px) / 447.98);
  }
}
.watchlist .pagination {
  margin-top: 10px;
}
@media (max-width: 1300px) {
  .watchlist .pagination {
    padding: 0 10px;
  }
}
@media (max-width: 767.98px) {
  .watchlist .pagination {
    margin-top: 15px;
  }
}
.watchlist_mod .watchlist-header__title {
  margin-right: auto;
  font-family: "Rubik";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}
.watchlist_mod .watchlist-header__title.mr-4 {
  margin-right: 2rem;
}
.watchlist_mod .watchlist-title {
  padding-right: 18px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  position: relative;
}
.watchlist_mod .watchlist-title::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  height: 18px;
  width: 1px;
  background-color: #B5BACB;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}
@media (max-width: 480px) {
  .watchlist_mod .watchlist-title::before {
    display: none;
  }
}
.watchlist_mod .watchlist-title::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  height: 18px;
  width: 1px;
  background-color: #323642;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}
@media (max-width: 480px) {
  .watchlist_mod .watchlist-title::after {
    display: none;
  }
}
.watchlist_mod .watchlist-header__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 100;
}
.watchlist_mod .watchlist-showing {
  border-right: 0;
}
.watchlist_mod .watchlist-header {
  z-index: 100;
}
@media (max-width: 767.98px) {
  .watchlist_mod .watchlist-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 10px;
  }
}
@media (max-width: 767.98px) {
  .watchlist_mod .watchlist-filters {
    margin-left: auto;
  }
}
@media (max-width: 767.98px) {
  .watchlist_mod .watchlist-showing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 480px) {
  .watchlist_mod .watchlist-showing {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .watchlist_mod .watchlist-header__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
}
.watchlist_mod .watchlist-filters__button_filter._opened {
  border: 1px solid transparent;
  background-color: #2A59FF;
}
.watchlist_mod .watchlist-filters__button_filter._opened::before,
.watchlist_mod .watchlist-filters__button_filter._opened::after {
  display: none;
}
.watchlist_mod .watchlist-filters__button_filter._opened i {
  color: #fff;
}
.watchlist_mod .watchlist-filters__button_filter._opened .watchlist-filters__name {
  color: #fff;
}
.watchlist_mod .watchlist-filters__button_filter {
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s, opacity ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
.watchlist_mod .watchlist-filters__button_filter i {
  font-size: 10px;
}
.watchlist_mod .watchlist-filters__button_filter i {
  color: #2A59FF;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.watchlist_mod .watchlist-orientation__button {
  -webkit-transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s, opacity ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
.watchlist_mod .watchlist-orientation__button i {
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.watchlist_mod .watchlist-orientation__button._current {
  background-color: #2A59FF;
  border: 1px solid transparent;
}
.watchlist_mod .watchlist-orientation__button._current i {
  color: #fff;
}
.watchlist_mod .filter {
  position: static;
  width: auto;
  height: auto;
  background: transparent;
  overflow: visible;
  -webkit-transform: none;
          transform: none;
  padding: 0;
  display: none;
  margin-bottom: 8px;
}
.watchlist_mod .filter__wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  padding: 0;
}
.watchlist_mod .filter .filter-search {
  border-radius: 6px;
}
@media (max-width: 1300px) {
  .watchlist_mod .filter .filter-search {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    min-width: auto;
  }
}
@media (max-width: 480px) {
  .watchlist_mod .filter .filter-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: calc(50% - 3px);
  }
}
@media (max-width: 767.98px) {
  .watchlist_mod .filter {
    margin-bottom: 12px;
  }
}
.watchlist_mod .filter._opened {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 20;
}
.filter._opened .filter__wrapper {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.filter._opened .select.is-active .select__body {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
  pointer-events: all;
}
.filter._opened .select.is-active .select__icon i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.watchlist_mod .select__header, .watchlist_mod .select__body, .watchlist_mod .filter-search {
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
.watchlist_mod .watchlist-product__part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.watchlist_mod .watchlist-product-single {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.watchlist_mod .watchlist-product-single__label {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #55606F;
  margin-bottom: 4px;
  display: none;
}
.watchlist_mod .watchlist-product__wrapper {
  height: 100%;
}
.watchlist_mod .watchlist-product-name {
  padding: 0;
}

.watchlist_mod .filter__form,
.watchlist_exchanges .filter__form {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  -ms-grid-columns: 315px 6px 315px 6px auto;
  grid-template-columns: 315px 315px auto;
  gap: 6px;
}
@media (max-width: 1300px) {
  .watchlist_mod .filter__form,
  .watchlist_exchanges .filter__form {
    padding: 0 10px;
  }
}
@media (max-width: 991.98px) {
  .watchlist_mod .filter__form,
  .watchlist_exchanges .filter__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .watchlist_mod .filter__form .filter-select,
  .watchlist_exchanges .filter__form .filter-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: calc(50% - 3px);
  }
}
@media (max-width: 480px) {
  .watchlist_mod .filter__form .filter-search,
  .watchlist_exchanges .filter__form .filter-search {
    max-width: none;
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
}

.watchlist_currency .watchlist-product-name__icon {
  width: 25px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25px;
          flex: 0 0 25px;
  height: 25px;
  border-radius: 6px;
}
.watchlist_currency .watchlist-legends, .watchlist_currency .watchlist-product__wrapper {
  -ms-grid-columns: 70px 156px 115px 115px 115px 115px 115px 115px 115px 115px 133px;
  grid-template-columns: 70px 156px 115px 115px 115px 115px 115px 115px 115px 115px 133px;
}
.watchlist_currency.shorten .watchlist-legends, .watchlist_currency.shorten .watchlist-product__wrapper {
  -ms-grid-columns: 70px 156px 115px 115px 115px 115px 115px 115px 1fr;
  grid-template-columns: 70px 156px 115px 115px 115px 115px 115px 115px 1fr;
}
.watchlist_currency.shorten .watchlist-legends.one-field, .watchlist_currency.shorten .watchlist-product__wrapper.one-field {
  grid-template-columns: 70px 156px 115px 115px 115px 90px 115px 95px 185px 1fr;
}
.watchlist_currency .watchlist-legend {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.watchlist_currency .watchlist-legends__title {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.watchlist_currency .watchlist-product__price {
  padding-left: 0;
}
.watchlist_currency .watchlist-product-rating-score__score {
  text-transform: capitalize;
}
.watchlist_currency .watchlist-product-name.watchlist-product__part {
  padding: 0;
}
.watchlist_currency .watchlist-product-name.watchlist-product__part .watchlist-product-name__box {
  padding-left: 14px;
}

.watchlist-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 7px;
  padding: 0 22px 0 9px;
}
@media (max-width: 767.98px) {
  .watchlist-title {
    padding-left: 0;
  }
}
.watchlist-title__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 11px;
}
.watchlist-title__icon i {
  color: #2A59FF
}
.watchlist-title__icon img {
  max-width: 100%;
}
.watchlist-title__title {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.watchlist-title__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 7px;
  border: 1px solid transparent;
  height: 30px;
  padding: 0 6px;
  border-radius: 4px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
.watchlist-title__wrapper:hover {
  border: 1px solid #2A59FF;
}

.watchlist-categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  gap: 10px;
  padding: 0 12px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
@media (max-width: 1250px) {
  .watchlist-categories {
    display: none;
  }
}
.watchlist-categories__el {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.watchlist-categories__el span {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.watchlist-categories__el span:hover {
  opacity: 0.6;
}
.watchlist-categories__el:not(:last-child)::after {
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 10px;
  height: 1px;
  margin-left: 8px;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  pointer-events: none;
}
.watchlist-categories__el_curent {
  color: #2A59FF;
  pointer-events: none;
}
.watchlist-show-small {
  display: none;
}
@media (max-width: 1024px) {
  .watchlist-hide-small {
    display: none;
  }
  .watchlist-show-small {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
  }
}
.category-button {
  display: flex;
  align-items: center;
  height: 30px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  background: transparent;
  color: #1E222A;
  transition: background .3s ease-out, color .3s ease-out;
  will-change: background, color
}
.category-button:not(.active):hover,
.category-button:not(.active):active {
  background: #d7dbe2;
  color: #283593
}
.category-button.active {
  background: #d0dbff;
  color: #1547fb
}
.dark .category-button {
  color: #8b98aa
}
.category-button:not(.active):hover,
.category-button:not(.active):active {
  background: #212739;
  color: #fff
}
.category-button.active {
  background: #313957;
  color: #fff;
}
.category-button:not(:last-child) {
  margin-right: .5rem;
}

.watchlist-showing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 9px;
  gap: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  position: relative;
  z-index: 9999;
}
.watchlist-showing::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  height: 18px;
  width: 1px;
  background-color: #B5BACB;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}
.watchlist-showing::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  height: 18px;
  width: 1px;
  background-color: #323642;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}
@media (max-width: 767.98px) {
  .watchlist-showing {
    display: none;
  }
}
.watchlist-showing__title {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.watchlist-showing__count {
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.watchlist-showing__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transition: -webkit-transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.watchlist-showing__icon i {
  font-size: .5rem;
  transition: transform ease-in-out 0.3s, color .3s ease-out;
  transition: transform ease-in-out 0.3s, color .3s ease-out;
  will-change: transform, color;
}
.watchlist-showing__icon i {
  color: #2A59FF;
}
.watchlist-showing .select {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.watchlist-showing .select.is-active .select__header,
.watchlist-showing .select:hover .select__header {
  border: 1px solid #2A59FF;
}
.watchlist-showing .select__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 6px;
  border: 1px solid transparent;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  border-radius: 4px;
}
.watchlist-showing .select__body {
  position: absolute;
  top: calc(100% + 1px);
  right: 50%;
  min-width: 100%;
  padding: 4px 0;
  border-radius: 6px;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate(50%, -10px);
          transform: translate(50%, -10px);
}
.watchlist-showing .select__current {
  margin-left: 3px;
}
.watchlist-showing .select__item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  padding: 6px 12px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}
.watchlist-showing .select.is-active .select__body {
  pointer-events: all;
  opacity: 1;
  -webkit-transform: translate(50%, 0px);
          transform: translate(50%, 0px);
}
.watchlist-showing .select.is-active .watchlist-showing__icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.watchlist-categories-spoiler {
  display: none;
  position: relative;
  z-index: 120;
  z-index: 120;
  padding: 0 20px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
@media (max-width: 1250px) {
  .watchlist-categories-spoiler {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 480px) {
  .watchlist-categories-spoiler {
    display: none;
  }
}
.watchlist-categories-spoiler .select__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  cursor: pointer;
  border: none !important
}
.watchlist-categories-spoiler .select__current {
  color: #2A59FF;
  pointer-events: none;
}
.watchlist-categories-spoiler .select__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.watchlist-categories-spoiler .select__icon i {
  font-size: .5rem;
  color: #2A59FF;
  -webkit-transition: -webkit-transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.filter .select__body,
.watchlist-categories-spoiler .select__body {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  opacity: 0;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  pointer-events: none;
}

.filter .select__body {
  top: calc(100% + 3px);
}
.filter .select__body-wrapper,
.watchlist-categories-spoiler .select__body-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  padding: 4px 0;
  border-radius: 6px;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
.filter .select__body-wrapper::before,
.watchlist-categories-spoiler .select__body-wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(243, 247, 251, 0.9);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.filter .select__body-wrapper::after,
.watchlist-categories-spoiler .select__body-wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(27, 32, 49, 0.95);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.filter .select__item,
.watchlist-categories-spoiler .select__item {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  cursor: pointer;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  padding: 6px 12px;
}

.filter.is-active .select__body,
.watchlist-categories-spoiler.is-active .select__body {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  pointer-events: all;
}
.filter.is-active .select__icon i,
.watchlist-categories-spoiler.is-active .select__icon i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.watchlist-product-genre {
  padding-left: 21px;
}
.watchlist-product-genre__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.watchlist-product-genre__el {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}

.watchlist-product-blockchain__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
.watchlist-product-blockchain__icon {
  height: 20px;
  max-width: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.watchlist-product-blockchain__icon img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

.watchlist-product-os__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: .5rem;
}
.watchlist-product-os__list img {
  max-width: 1rem;
  max-height: 1rem;
  width: 100%;
  object-fit: contain;
}

.watchlist-product-nft__marker {
  width: 60px;
  height: 20px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  color: #fff;
  cursor: default;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}
.watchlist-product-nft__marker_green {
  background-color: #71BA57;
}
.watchlist-product-nft__marker_yellow {
  background-color: #EAB300;
}
.watchlist-product-nft__marker.live {
  background-color: #71BA57;
}
.watchlist-product-nft__marker.presale {
  background-color: #B23CFD;
}
.watchlist-product-nft__marker.beta {
  background-color: #1266F1;
}
.watchlist-product-nft__marker.alpha {
  background-color: #39C0ED;
}
.watchlist-product-nft__marker.development,
.watchlist-product-nft__marker.dev {
  background-color: #FFA900;
}
.watchlist-product-nft__marker.cancel {
  background-color: #b14c6b;
}

.watchlist-product-status__status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 20px;
  height: 20px;
  font-size: 1rem;
  color: #2A59FF;
  border-radius: 50%;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}
.watchlist-product-status__status._green {
  color: #71ba57
}
.watchlist-product-status__status._red {
  color: #b14c6b
}

.watchlist-product-f2p__status {
  padding: 0 10px;
  height: 20px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  background-color: #B14C6B;
}

.watchlist-product-p2e__status {
  padding: 0 10px;
  height: 20px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  background-color: #2A59FF;
}

.watchlist-product-rating-day__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 2px;
}
.watchlist-product-rating-day__price {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}
.watchlist-product-rating-day__grow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.watchlist-product-rating-day__icon {
  font-size: .5rem;
  position: relative;
  z-index: -1;
}
.watchlist-product-rating-day__percent {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.watchlist-product-rating-day__grow.minus .watchlist-product-rating-day__grow-down {
  opacity: 1;
}
.watchlist-product-rating-day__grow.minus .watchlist-product-rating-day__icon,
.watchlist-product-rating-day__grow.minus .watchlist-product-rating-day__percent {
  color: #B14C6B;
}

.watchlist-product-rating-day__grow.plus .watchlist-product-rating-day__grow-up {
  opacity: 1;
}
.watchlist-product-rating-day__grow.plus .watchlist-product-rating-day__icon,
.watchlist-product-rating-day__grow.plus .watchlist-product-rating-day__percent {
  color: #71BA57;
}

.watchlist-product-start__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.watchlist-product-start__bold {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 13px;
  line-height: 137.19%;
}
.watchlist-product-start__normal {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 137.19%;
  color: #55606F;
}

.watchlist-product-sale {
  padding-left: 21px;
}
.watchlist-product-sale__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 48px;
}
.watchlist-product-sale__title {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #55606F;
}
.watchlist-product-sale__value {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}

.watchlist-product-links {
  padding-left: 24px;
}
.watchlist-product-links__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
.watchlist-product-links__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.watchlist-product-links__link:hover {
  opacity: 0.6;
}
.watchlist-product-links__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.watchlist-product-links__text {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #55606F;
}

.watchlist-product-rating-score__score {
  padding: 0 10px;
  height: 20px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}
.watchlist-product-rating-score .watchlist-product-rating-score__score._green {
  background-color: #71BA57
}
.watchlist-product-rating-score .watchlist-product-rating-score__score._orange {
  background-color: #FFA900
}
.watchlist-product-rating-score .watchlist-product-rating-score__score._red {
  background-color: #F93154
}

.watchlist-product-fiat .watchlist-product-single__content {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.watchlist-product-fiat__list {
  white-space: nowrap;
  width: 100%;
}

.watchlist-product-more__button {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
}
.watchlist-product-more__button:hover {
  background-color: rgba(204, 204, 204, 0.1333333333);
}
.watchlist-product-more__button img {
  width: 7.3px;
}

.watchlist-product-category__content {
  font-family: "Rubik";
  font-weight: 500;
  text-align: center;
}
.pagination {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: 1fr 2fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  z-index: 30;
}
@media (max-width: 767.98px) {
  .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    gap: 8px;
    margin-bottom: 5px;
  }
}

.pagination-showing {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}
.pagination-showing__count {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.main-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
  z-index: 20;
}
.main-pagination__nav-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 35px;
  height: 35px;
  color: #2A59FF;
  font-size: .75rem;
  border-radius: 4px;
  -webkit-transition: border ease-in-out 0.3s, opacity ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, opacity ease-in-out 0.3s;
  cursor: pointer;
  position: relative;
}
@media (max-width: 480px) {
  .main-pagination__nav-btn {
    width: 33px;
    height: 33px;
  }
}
.main-pagination__nav-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.2) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  border-radius: 4px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.main-pagination__nav-btn:hover {
  border: 1px solid #2A59FF !important
}
.main-pagination__pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6px;
  height: 35px;
}
@media (max-width: 767.98px) {
  .main-pagination__pages {
    gap: calc(2px + 4 * (100vw - 320px) / 447.98);
  }
}
@media (max-width: 480px) {
  .main-pagination__pages {
    height: 33px;
  }
}
.main-pagination__page-num {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}
@media (max-width: 480px) {
  .main-pagination__page-num {
    width: 33px;
    height: 33px;
  }
}
.main-pagination__page-num:hover {
  background-color: rgba(41, 87, 255, 0.1);
}
.main-pagination__page-num_current {
  background-color: #2A59FF;
  color: #fff;
  pointer-events: none;
}
.main-pagination__page-last {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}
@media (max-width: 480px) {
  .main-pagination__page-last {
    width: 33px;
    height: 33px;
  }
}
.main-pagination__page-last:hover {
  background-color: rgba(41, 87, 255, 0.3);
}

.show-rows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
}
@media (max-width: 767.98px) {
  .show-rows {
    display: none;
  }
}
.show-rows__label {
  font-size: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin-right: 8px;
}
.show-rows__count {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.show-rows__icon {
  margin-left: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.show-rows__icon i {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.show-rows .select {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.show-rows .select.is-active .select__header,
.show-rows .select:hover .select__header {
  border: 1px solid #2A59FF;
}
.show-rows .select__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 6px;
  border: 1px solid transparent;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  border-radius: 4px;
}
.show-rows .select__body {
  position: absolute;
  top: calc(100% + 1px);
  right: 50%;
  min-width: 100%;
  padding: 4px 0;
  border-radius: 6px;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate(50%, -10px);
          transform: translate(50%, -10px);
}
.show-rows .select__current {
  margin-left: 3px;
}
.show-rows .select__item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  padding: 6px 12px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}
.show-rows .select.is-active .select__body {
  pointer-events: all;
  opacity: 1;
  -webkit-transform: translate(50%, 0px);
          transform: translate(50%, 0px);
}
.show-rows .select.is-active .watchlist-showing__icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.watchlist-main {
  z-index: 10;
}
@media (max-width: 1300px) {
  .watchlist-main {
    position: relative;
  }
  .watchlist-main::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 125px;
    width: calc(46px + 79 * (100vw - 320px) / 980);
    height: 100%;
    z-index: 10;
    pointer-events: none;
    background: -webkit-gradient(linear, right top, left top, color-stop(24.71%, #EAEFFE), color-stop(113.73%, rgba(220, 226, 242, 0)));
    background: linear-gradient(270deg, #EAEFFE 24.71%, rgba(220, 226, 242, 0) 113.73%);
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s;
  }
  .watchlist-main::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: calc(46px + 79 * (100vw - 320px) / 980);
    height: 100%;
    z-index: 10;
    pointer-events: none;
    background: -webkit-gradient(linear, right top, left top, color-stop(24.71%, #141621), color-stop(113.73%, rgba(23, 25, 39, 0)));
    background: linear-gradient(270deg, #141621 24.71%, rgba(23, 25, 39, 0) 113.73%);
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s;
  }
  .watchlist-main.hide-scrollable-effect::after,
  .watchlist-main.hide-scrollable-effect::before {
    opacity: 0 !important
  }
}
.watchlist-main__wrapper {
  overflow: auto;
}
@media (max-width: 1300px) {
  .watchlist-main__wrapper {
    padding: 0 10px;
  }
}
.watchlist-main__header {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 59px 180px 124px 87px 84px 153px 149px 176px 172px 53px;
  grid-template-columns: 59px 180px 124px 87px 84px 153px 149px 176px 172px 53px;
  border-radius: 4px;
  height: 40px;
  min-width: 1278px;
  position: relative;
  margin-bottom: 5px;
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  z-index: 40;
}
.watchlist-main__header.no-chart {
  -ms-grid-columns: 59px 180px 124px 87px 84px 153px 149px 176px 53px;
  grid-template-columns: 59px 180px 124px 87px 84px 153px 149px 176px 53px;
  min-width: 1100px;
}

.watchlist-main._in-the-end::before, .watchlist-main._in-the-end::after {
  opacity: 0;
}

.watchlist-legends .sortable {
  cursor: pointer;
}
.watchlist-legends__order {
  padding-left: 38px;
  gap: 5px;
}
.watchlist-legends__icon i {
  font-size: .5rem;
}
.watchlist-legends__icon i {
  color: #2A59FF;
}
.watchlist-legends__icon {
  display: inline-block
}
.watchlist-legends__name {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #565E78;
}
.dark .watchlist-legends__name {
  color: #7f8aad;
}
.watchlist-legends__settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.watchlist-legends__blockchain, .watchlist-legends__device, .watchlist-legends__nft, .watchlist-legends__status, .watchlist-legends__f2p, .watchlist-legends__p2e, .watchlist-legends__rating {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.watchlist-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-content: center;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
.watchlist-legend.sortable {
  cursor: pointer;
}

.watchlist-main__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5px;
  z-index: 39;
}

.watchlist-product {
  position: relative;
  border-radius: 4px;
  margin-bottom: 5px;
  width: 1280px;
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  z-index: 40;
}
.watchlist-product:hover .watchlist-product__wrapper::before {
  opacity: 1;
}
.watchlist-product:hover .watchlist-product-name__box::before {
  opacity: 1;
  z-index: 4;
}
.watchlist-product__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 59px 180px 124px 87px 84px 153px 149px 176px 172px 53px;
  grid-template-columns: 59px 180px 124px 87px 84px 153px 149px 176px 172px 53px;
  border-radius: 4px;
  height: 55px;
  z-index: 3;
  position: relative;
}
.watchlist-product__wrapper.no-chart {
  -ms-grid-columns: 59px 180px 124px 87px 84px 153px 149px 176px 53px;
  grid-template-columns: 59px 180px 124px 87px 84px 153px 149px 176px 53px;
}
.watchlist-product__wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(28, 49, 109, 0) -73.23%, rgba(2, 10, 196, 0.06) 91.58%);
  -webkit-filter: drop-shadow(0px 4px 4px rgba(30, 33, 48, 0.11));
          filter: drop-shadow(0px 4px 4px rgba(30, 33, 48, 0.11));
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  z-index: -1;
}
.watchlist-product__part {
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.watchlist-product__part_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.watchlist-product__favorite {
  width: 11px;
  height: 11px;
  position: relative;
  cursor: pointer;
}
.watchlist-product__favorite i {
  transition: color .3s ease-out;
  will-change: color;
}
.watchlist-product__favorite:hover i {
  color: #EAB300
}
.watchlist-product__num {
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #515764;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
@media (max-width: 1200px) {
  .watchlist-product__num {
    margin-left: calc(4px + 6 * (100vw - 991.98px) / 208.02);
  }
}
@media (max-width: 991.98px) {
  .watchlist-product__num {
    margin-left: calc(4px + 6 * (100vw - 567.98px) / 424);
  }
}
@media (max-width: 567.98px) {
  .watchlist-product__num {
    margin-left: 10px;
  }
}
.watchlist-product__price {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding-left: 32px;
}
.watchlist-product__text {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}
.watchlist-product__market-cap {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.watchlist-favorite {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  z-index: 25;
  color: #1E222A
}
.dark .watchlist-favorite {
  color: #fff
}
.watchlist-favorite i {
  pointer-events: none;
  transition: color .3s ease-out;
  will-change: color;
}

.watchlist-favorite._favorite .watchlist-favorite__filled {
  color: #EAB300;
}

.watchlist-product-name._fixed .watchlist-product-name__box {
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  position: absolute;
  left: 0;
  padding-left: 14px;
  -webkit-box-shadow: 8px 0px 10px -7px rgba(0, 0, 0, 0.2);
          box-shadow: 8px 0px 10px -7px rgba(0, 0, 0, 0.2);
}

.watchlist-product-rate {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.watchlist-product-rate__num.minus {
  color: #B14C6B;
}

.watchlist-product-rate__num.plus {
  color: #71BA57;
}

.watchlist-product-rate__num {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.watchlist-product-rate__num i {
  font-size: .5rem;
  margin-right: 0.25rem;
}

.watchlist-product-volume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 25px;
}
.watchlist-product-volume__subtext {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #55606F;
}

.watchlist-product-supply {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.watchlist-product-supply__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.watchlist-product-supply__icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  overflow: hidden;
}
.watchlist-product-supply__icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.watchlist-product-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.watchlist-product-settings__button {
  background-color: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}
.watchlist-product-settings__button span {
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #2A59FF;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}

.watchlist-product-settings__button._opened span {
  background-color: #2A59FF;
}
.crypto-chart,
.crypto-chart div:first-child,
.crypto-chart canvas {
    width: 160px;
    height: 50px;
    border-radius: 0.25rem;
}

.watchlist-main__ruler {
  position: absolute;
  width: 100%;
}

.watchlist-main__list._grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr;
  width: 1280px;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .watchlist-main__list._grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    min-width: 0;
    margin: 0 auto;
  }
}
.watchlist-main__list._grid .watchlist-product {
  min-width: 0;
  width: 635px;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .watchlist-main__list._grid .watchlist-product {
    width: 100%;
    max-width: calc(100vw - 20px);
    min-width: 0;
  }
}
@media (max-width: 654.98px) {
  .watchlist-main__list._grid .watchlist-product {
    width: 635px;
  }
}
.watchlist-main__list._grid .watchlist-product::before {
  content: "";
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  top: 0;
  left: 0;
  min-width: 1278px;
  height: 100%;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  background: linear-gradient(53.56deg, #EBF2FF -9.61%, #F1F1F1 90.49%);
  opacity: 0.6;
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border-radius: 4px;
  z-index: -1;
  opacity: 0;
}
.watchlist-main__list._grid .watchlist-product::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  min-width: 1278px;
  height: 100%;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.5) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  border-radius: 4px;
  z-index: -1;
  opacity: 0;
}
.watchlist-main__list._grid .watchlist-product:hover .watchlist-product-name__box::before, .watchlist-main__list._grid .watchlist-product::after .watchlist-product-name__box::before {
  opacity: 1;
  z-index: 1;
}
.watchlist-main__list._grid .watchlist-product .watchlist-product-name {
  left: 0;
}
.watchlist-main__list._grid .watchlist-product-single {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 14px;
  padding-top: 0;
  padding-bottom: 0;
}
.watchlist-main__list._grid .watchlist-product-single__label {
  display: inline-block;
}
.watchlist-main__list._grid .watchlist-product-genre {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
}
.watchlist-main__list._grid .watchlist-product-genre__list {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.watchlist-main__list._grid .watchlist-product__wrapper {
  -ms-grid-columns: 55px 84px 118px 118px 118px 118px;
  grid-template-columns: 55px 84px 118px 118px 118px 118px;
  -ms-grid-rows: 1fr 22px auto;
  grid-template-rows: 1fr auto;
  row-gap: 22px;
      grid-template-areas: "actions info info genre blockchain os" "actions nft status f2p p2e rating-day";
}

.watchlist-filters {
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
}
@media (max-width: 1250px) {
  .watchlist-filters {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
}
.watchlist-filters__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  gap: 5px;
  padding: 8px 6px;
  z-index: 10;
  padding: 8px 12px;
  border-radius: 6px;
  -webkit-transition: border ease-in-out 0.3s, opacity ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
.watchlist-filters__button i {
  color: #2A59FF
}
.watchlist-filters__button.disabled {
  pointer-events: none;
  opacity: .5;
}
@media (max-width: 767.98px) {
  .watchlist-filters__button {
    display: none;
  }
}
.watchlist-filters__button_filter {
  position: relative;
}
.watchlist_mod .watchlist-filters__button_filter._opened:hover {
  background-color: #1c3aa6;
}
.watchlist-filters__button_filter span {
  pointer-events: none;
}
.watchlist-filters__button_filter::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.4) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  border-radius: 4px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  pointer-events: none;
}
@media (max-width: 1250px) {
  .watchlist-filters__button_filter {
    margin-left: auto;
  }
}
@media (max-width: 767.98px) {
  .watchlist-filters__button_filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.watchlist-filters__button_customize {
  border: 1px solid transparent;
}
.watchlist-filters__button_customize:hover {
  border: 1px solid #2A59FF;
}
.watchlist-filters__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.watchlist-filters__name {
  font-family: "Rubik";
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.watchlist-orientation {
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
}
.watchlist-orientation__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 9px 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: transparent;
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  position: relative;
}
.watchlist-orientation__button.disabled {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}
.watchlist-orientation__button:not(.disabled):not(._current):hover {
  background-color: rgba(204, 204, 204, 0.145);
}
.dark .watchlist-orientation__button:not(.disabled):not(._current):hover {
  background-color: rgba(84, 84, 84, 0.145);
}
.watchlist-orientation__button:not(.disabled):not(._current):hover i {
  color: #2A59FF;
}
.watchlist-orientation__button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.4) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  border-radius: 4px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.watchlist-orientation__button i {
  color: #1E222A;
}
.dark .watchlist-orientation__button i {
  color: #646e74
}
.watchlist-orientation__button_disabled {
  pointer-events: none;
  opacity: 0.6;
}

.watchlist-orientation__button._current i {
  color: #2A59FF;
}

.homepage-background-desctop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.homepage-background-desctop img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.watchlist-showing__select .select__header {
  border: 1px solid transparent;
}

.scroll-none {
  overflow: hidden;
}
.cookies-alert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin: 0 auto;
  padding: .55rem 1.5rem;
  border-radius: .5rem;
  position: fixed;
  bottom: 0;
  left: 2rem;
  right: auto;
  background: #2A59FF;
  transform: translateY(300%);
  z-index: 130;
  box-shadow: none;
  transition: transform .3s ease-out;
  will-change: transform;
}
.dark .cookies-alert {
  background: #222844
}
.cookies-alert.active {
  transform: translateY(-2rem);
}
.cookies-alert button {
  background: inherit;
  border: 2px solid #fff;
  border-radius: 5rem;
  padding: .5rem 1.5rem;
  cursor: pointer;
  white-space: nowrap;
  transition: background .3s ease-out;
  will-change: background;
}
.cookies-alert button:hover {
  background: hsla(0, 0%, 100%, .2);
}
.cookies-alert button, .cookies-alert p {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-size: .85rem;
}
.cookies-alert p {
margin-bottom: 0;
margin-right: 1.5rem;
}
.cookies-alert button, .cookies-alert p span {
margin: 0 .25rem
}
.cookies-alert button, .cookies-alert p a {
margin-left: .25rem
}
.cookies-alert i {
color: #fff;
width: 1rem;
height: 1rem;
margin-right: .5rem;
}
.cookies-alert p a {
text-decoration: underline;
}
.cookies-alert p a:hover,
.cookies-alert p a:active {
color: #fff
}
@media (max-width: 512px) {
.cookies-alert {
  left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: calc(100% - 2rem);
}
.cookies-alert.active {
  transform: translateY(-1rem);
}
.cookies-alert i {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}
.cookies-alert button, .cookies-alert p {
  align-items: center;
  justify-content: center;
  font-size: .75rem;
}
.cookies-alert button {
  padding: 0.35rem 1rem;
  border: 1px solid #fff
}
}
.not-found-area {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  padding: 1rem 0
}

.not-found-icon,
.not-found-area svg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  width: 6rem;
  height: 6rem;
  color: #757575
}
.dark .not-found-icon,
.dark .not-found-area svg {
  color: #bdbdbd
}

.not-found-header,
.not-found-area .h6 {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}
.not-found-area.small .not-found-header,
.not-found-area.small .h6 {
  font-size: .85rem;
  line-height: 1.25;
}

html.dark .not-found-header,
html.dark .not-found-area .h6 {
  color: #757575
}

.not-found-desc,
.not-found-area .desc {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.not-found-area.small .not-found-desc,
.not-found-area.small .desc {
  font-size: .75rem;
}
[text-tooltip] {
  position: relative;
  z-index: 0;
}

[text-tooltip]:before,
[text-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;
  transition: 0.2s ease-out;
  will-change: transform;
}

[text-tooltip]:before {
  content: attr(text-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: .5rem;
  font-family: "Roboto";
  font-size: .85rem;
  line-height: 1.25;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg,
      rgba(59, 72, 80, 0.44),
      rgba(59, 68, 75, 0.44),
      rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.025rem;
  text-align: center;
  white-space: pre-wrap;
  text-transform: none;
  transform: translate(-50%, -5px) scale(0.5);
}
.wide-tooltip[text-tooltip]::before {
  min-width: 14rem;
  max-width: 22rem;
  line-height: 1.35;
  white-space: initial;
}

[text-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s;
  transform-origin: top;
  transform: translateX(-50%) scaleY(0);
}

[text-tooltip]:hover:before,
[text-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

[text-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}

[text-tooltip]:hover:after {
  transition-delay: 0.4s;
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
[text-tooltip][flow^="left"]:before,
[text-tooltip][flow^="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

[text-tooltip][flow^="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[text-tooltip][flow^="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

[text-tooltip][flow^="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[text-tooltip][flow^="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

[text-tooltip][flow^="right"]:before,
[text-tooltip][flow^="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[text-tooltip][flow^="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[text-tooltip][flow^="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[text-tooltip][flow^="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[text-tooltip][flow^="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

[text-tooltip][flow^="bottom"]:before,
[text-tooltip][flow^="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[text-tooltip][flow^="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[text-tooltip][flow^="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[text-tooltip][flow^="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

.dark .pu-skeleton {
  background-color: rgb(39 48 70) !important;
  background-image: linear-gradient(90deg, rgb(39 48 70), rgb(45 54 78), rgb(39 48 70)) !important;
}

.loading {
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.tooltips {
  display: none;
}
.tip {
  cursor: pointer;
}
.tippy-box[data-placement^=top]>.tippy-svg-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-svg-arrow:after,.tippy-box[data-placement^=top]>.tippy-svg-arrow>svg{top:16px;transform:rotate(180deg)}.tippy-box[data-placement^=bottom]>.tippy-svg-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-svg-arrow>svg{bottom:16px}.tippy-box[data-placement^=left]>.tippy-svg-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-svg-arrow:after,.tippy-box[data-placement^=left]>.tippy-svg-arrow>svg{transform:rotate(90deg);top:calc(50% - 3px);left:11px}.tippy-box[data-placement^=right]>.tippy-svg-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-svg-arrow:after,.tippy-box[data-placement^=right]>.tippy-svg-arrow>svg{transform:rotate(-90deg);top:calc(50% - 3px);right:11px}.tippy-svg-arrow{width:16px;height:16px;fill:#333;text-align:initial}.tippy-svg-arrow,.tippy-svg-arrow>svg{position:absolute}
.tippy-tooltip.light-theme {
  background-color: #f8faff;
  border-radius: .5rem;
  font-family: "Roboto";
  font-size: .75rem;
  font-weight: 400;
  color: #283593;
  box-shadow: 0 3px 6px rgba(175,175,175,0.19), 0 3px 6px rgba(175,175,175,0.23);
}
.tippy-tooltip.light-theme .tippy-roundarrow,
.tippy-tooltip.light-theme .tippy-svg-arrow {
  fill: #c1c7f2
}
.tippy-tooltip.light-theme .tippy-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  padding: .75rem;
}
.tippy-tooltip.light-theme .tippy-content .h6 {
  font-size: .85rem;
  font-weight: 600;
  margin-bottom: .5rem;
}
.tippy-tooltip.light-theme .tippy-content p {
  margin: 0
}
.tippy-tooltip.light-theme .tippy-content p:not(:last-child) {
  margin-bottom: .25rem
}
.tippy-tooltip.light-theme .tippy-content a {
  color: #2A59FF;
  transition: color .3s ease-out;
  will-change: color;
}
.tippy-tooltip.light-theme .tippy-content a:hover,
.tippy-tooltip.light-theme .tippy-content a:active {
  color: #0072ff
}
.tippy-tooltip.light-theme .tippy-content ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 2rem;
}
.tippy-tooltip.light-theme .tippy-content ul li {
  list-style: disc;
}
.tippy-tooltip.light-theme[data-placement^=top]>.tippy-arrow:before {
  border-radius: .5rem;
  border-top-color: #f8faff
}

.tippy-tooltip.light-theme[data-placement^=bottom]>.tippy-arrow:before {
  border-radius: .5rem;
  border-bottom-color: #f8faff
}

.tippy-tooltip.light-theme[data-placement^=left]>.tippy-arrow:before {
  border-radius: .5rem;
  border-left-color: #f8faff
}

.tippy-tooltip.light-theme[data-placement^=right]>.tippy-arrow:before {
  border-radius: .5rem;
  border-right-color: #f8faff
}

.tippy-tooltip.light-theme>.tippy-backdrop {
  background-color: #f8faff
}

.tippy-tooltip.light-theme>.tippy-svg-arrow {
  fill: #f8faff
}
.tippy-box[data-animation=scale][data-placement^=top] {
  transform-origin: bottom
}

.tippy-box[data-animation=scale][data-placement^=bottom] {
  transform-origin: top
}

.tippy-box[data-animation=scale][data-placement^=left] {
  transform-origin: right
}

.tippy-box[data-animation=scale][data-placement^=right] {
  transform-origin: left
}

.tippy-box[data-animation=scale][data-state=hidden] {
  transform: scale(.85);
  opacity: 0
}

.dark .tippy-tooltip.light-theme {
  background-color: #222844;
  color: #fff;
  box-shadow: 0 3px 6px rgba(75,75,75,0.19), 0 3px 6px rgba(75,75,75,0.23);
}
.dark .tippy-tooltip.light-theme .tippy-content a {
  color: #2994FF
}
.dark .tippy-tooltip.light-theme .tippy-content a:hover,
.dark .tippy-tooltip.light-theme .tippy-content a:active {
  color: #0072ff
}
.dark .tippy-tooltip.light-theme[data-placement^=top]>.tippy-arrow:before {
  border-top-color: #222844
}

.dark .tippy-tooltip.light-theme[data-placement^=bottom]>.tippy-arrow:before {
  border-bottom-color: #222844
}

.dark .tippy-tooltip.light-theme[data-placement^=left]>.tippy-arrow:before {
  border-left-color: #222844
}

.dark .tippy-tooltip.light-theme[data-placement^=right]>.tippy-arrow:before {
  border-right-color: #222844
}

.dark .tippy-tooltip.light-theme>.tippy-backdrop {
  background-color: #222844
}
.first-prepage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.first-prepage .h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: .5rem;
}
.verified {
  color: #2994FF
}

.first-prepage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.first-prepage h1 {
  font-family: "Rubik";
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .first-prepage h1 {
      font-size: 1.75rem;
  }
}
@media (max-width: 580px) {
  .first-prepage h1 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
  }

  .first-prepage {
      padding: 1.5rem 0;
  }
}

.document p {
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

.document h2 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.filter-select .mx-datepicker {
  width: 100%
}
.mx-datepicker-main {
  background-color: #F2F6FA !important;
  border: 1px solid #B5BACB !important;
  box-shadow: none !important;
  color: #55606F !important;
  border-radius: 6px !important;
}
.filter-select .mx-input:hover, .filter-select .mx-input:focus {
  border: 1px solid #B5BACB
}
.mx-calendar-content .cell.active {
  background-color: #88abf0
}
.mx-calendar-content .cell:hover {
  background-color: #e3f2ff;
}
.mx-btn:hover {
  border-color: #88abf0;
  color: #88abf0
}
.mx-time-column .mx-time-item.active {
  color: #88abf0
}
.mx-table th {
  opacity: .75;
}
.mx-icon-calendar, .mx-icon-clear {
  color: #2A59FF !important
}
.filter-select .mx-input {
  font-family: "Rubik";
  font-weight: 300;
  background-color: transparent;
  height: 35px;
  border-radius: 6px;
  border: 1px solid #B5BACB
}
html.dark .mx-datepicker-main {
  background-color: rgba(27, 32, 49, 0.95) !important;
  border: 1px solid rgba(69, 78, 83, 0.5) !important;
  color: #fff !important;
  backdrop-filter: blur(4px);
}
html.dark .mx-input {
  border: 1px solid rgba(69, 78, 83, 0.5);
  color: #fff
}
.dark .filter-select .mx-input:hover, .dark .filter-select .mx-input:focus {
  border: 1px solid rgba(69, 78, 83, 0.5)
}
html.dark .mx-input::placeholder {
  color: #55606F;
}
html.dark .mx-inpu::-moz-placeholder {
  color: #55606F;
}
html.dark .mx-inpu::-ms-input-placeholder {
  color: #55606F;
}
html.dark .mx-time {
  background: #23232e;
}
html.dark .mx-calendar-content .cell:hover,
html.dark .mx-time-column .mx-time-item:hover {
  background-color: #2d456a;
  color: #fff
}
html.dark .mx-calendar-content .cell.in-range,
html.dark .mx-calendar-content .cell.hover-in-range {
  background-color: rgb(136 171 240 / 15%);
  color: #fff
}

.project-chart-tooltip {
  padding: .25rem
}
.project-chart-tooltip .h6 {
  font-size: .65rem;
  font-weight: 400;
}
.project-chart-tooltip p.chart-money-value {
  font-size: .8rem;
  font-weight: 600;
  margin: 0
}
.watchlist-product-fiat__remainder {
  margin-left: .25rem;
  font-size: .75rem;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition: color .3s ease-out;
  will-change: color;
}
.watchlist-product-fiat__remainder:hover,
.watchlist-product-fiat__remainder:active {
  color: #2A59FF
}
